import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PermissionsEngine, RestService } from 'src/app/core/services';
import { Columns } from 'src/app/core/models/models';
import { API_KEYPOINT, APP_CONSTANTS, APP_PATTERN } from 'src/app/core/constants';

@Component({
  selector: 'app-onboard-new-user',
  templateUrl: './onboard-new-user.component.html',
  styleUrls: ['./onboard-new-user.component.scss'],
})
export class OnboardNewUserComponent {
  @Input() isOnboardModalShow: any;
  @Input() userRoleList: any = [];
  @Output() refreshList = new EventEmitter<any>();
  userForm: any;
 
  contactNumberPattern = APP_PATTERN.contactNumber;
  namePattern = APP_PATTERN.name;
  emailPattern = APP_PATTERN.email;
  profAgencyPattern = APP_PATTERN.profAgency;
  columns!: Array<Columns>;
  @ViewChild('actionTemplate', { static: true }) actionTemplate: any;
  @ViewChild('statusTemplate', { static: true }) statusTemplate: any;
  createRoleForm: any;
  @Input() recordId: any;
  queryParams: any = {
    search: '',
    limit: APP_CONSTANTS.PAGE_SIZE,
    pageno: 1,
    roleId: '',
    sortBy: '',
    orderBy: '',
  };
  cleared = false;
  @ViewChild('frmUser') templateForm: any;
  userFormData: {
    firstName: string;
    lastName: string;
    email: string;
    contactNumber: string;
    active: true;
    roleId: string;
    agency: string;
  } = {
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    active: true,
    roleId: '',
    agency: '',
  };
  btnText: string;
  createRoleArray: any = [];
  // userRoleList: any = [];
  ischecked: any;
  cancelPopUp: boolean = false;
  isNewUser: boolean = false;

  constructor(
    private messageService: MessageService,
    private route: ActivatedRoute,
    public permissionsEngine: PermissionsEngine,
    private restService: RestService
  ) {
    this.createRoleForm = new FormGroup({
      limit: new FormControl('10'),
      pageno: new FormControl('1'),
      require: new FormControl(''),
    });
    this.userForm = new FormGroup({
      firstName: new FormControl('', [
        Validators.required,
        Validators.maxLength(20),
        Validators.pattern(this.namePattern),
      ]),
      lastName: new FormControl('', [
        Validators.required,
        Validators.maxLength(20),
        Validators.pattern(this.namePattern),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.maxLength(40),
        Validators.pattern(this.emailPattern),
      ]),
      contactNumber: new FormControl(''),
      active: new FormControl(true),
      roleId: new FormControl('defaultRole', Validators.required),
      agency: new FormControl('', [
        Validators.minLength(2),
        Validators.maxLength(40),
        Validators.pattern(this.profAgencyPattern),
      ]),
    });
    this.userForm.get('email').valueChanges.subscribe((value: string) => {
      if (this.userForm.get('email').value) {
        this.userForm
          .get('email')
          .setValue(value.toLowerCase(), { emitEvent: false });
      }
    });

    this.columns = [
      {
        key: 'id',
        sortKey: 'userId',
        label: 'User ID',
        thClass: 'd-flex justify-content-center align-items-center',
        tdClass: 'text-center',
      },
      {
        key: 'firstName',
        sortKey: 'firstName',
        label: 'First Name',
        thClass: 'd-flex justify-content-center align-items-center',
        tdClass: 'text-center',
      },
      {
        key: 'lastName',
        sortKey: 'lastName',
        label: 'Last Name',
        thClass: 'd-flex justify-content-center align-items-center',
        tdClass: 'text-center',
      },
      {
        key: 'roleName',
        sortKey: 'roleName',
        label: 'Role',
        thClass: 'd-flex justify-content-center align-items-center',
        tdClass: 'text-center',
      },
      {
        key: 'email',
        sortKey: 'email',
        label: 'Email ID',
        thClass: 'd-flex justify-content-center align-items-center',
        tdClass: 'text-center text-break',
      },
      {
        key: 'contactNumber',
        label: 'Mobile No.',
        thClass: 'd-flex justify-content-center align-items-center',
        tdClass: 'text-center',
      },
      {
        key: 'action',
        label: 'Status',
        template: this.statusTemplate,
        thClass: 'd-flex justify-content-center align-items-center',
        tdClass: 'text-center',
      },
      {
        key: 'action',
        label: 'Actions',
        template: this.actionTemplate,
        thClass: 'd-flex justify-content-center align-items-center',
        tdClass: 'text-center',
      },
    ];
    this.route.queryParams.subscribe((params) => {
      if (params.roleId) {
        this.queryParams.roleId = parseInt(params.roleId);
      }
    });
  }
  ngOnInit(): void {
    if (this.recordId) {
      this.btnText = 'Update';
      this.restService.get(API_KEYPOINT.user.userDetails + this.recordId)
        .subscribe((data: any) => {
          this.userFormData = { ...this.userFormData, ...data.result };
          this.userForm.patchValue({
            email: data.result.email,
            firstName: data.result.firstName,
            lastName: data.result.lastName,
            agency: data.result.agency,
            contactNumber: data.result.contactNumber,
            roleId: data.result.roleId,
          });
        });
    } else {
      this.btnText = 'Save';
    }
  }
  resetUserFormData() {
   let emailValue = '';

    if (this.recordId) {
      emailValue = this.userForm.get('email').value;
    } 
    this.userFormData = {
      firstName: '',
      lastName: '',
      email: emailValue, 
      contactNumber: '',
      active: true,
      roleId: '',
      agency: '',
    };
    this.userForm.reset({
      email: emailValue, 
      roleId: 'defaultRole'
    });
    this.isNewUser = false;
}

  get first() {
    return this.userForm.get('firstName');
  }
  get last() {
    return this.userForm.get('lastName');
  }
  get email() {
    return this.userForm.get('email');
  }
  get phone() {
    return this.userForm.get('contactNumber');
  }
  
  get agency() {
    return this.userForm.get('agency');
  }
  get role() {
    return this.userForm.get('roleId');
  }

  clearInput() {
    // this.userFormData.contactNumber = '' // Clear the input value
    this.cleared = true;
    this.userForm.get('contactNumber').reset();
  }
  onInputFocus(event: FocusEvent) {
    const input = event.target as HTMLInputElement;
    input.setSelectionRange(0, input.value.length);
  }
  onInputChanged(event: Event) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/\D/g, ''); // Remove non-numeric characters
    this.userFormData.contactNumber = input.value; // Update the form control value
  }

  cancelShowModalForm() {
    this.isOnboardModalShow = false;
    this.refreshList.emit(false);
    this.resetUserFormData();
  }

  //THIS FUNCTION TAKES DATA FROM THE INPUT FIELDS IN THE FORM AND PASSES THEM TO THE SUBMIT FUNCTION.
  submitMiddleware(formData: any) {
    this.userFormData.firstName = this.userForm.get('firstName').value || '';
    this.userFormData.lastName = this.userForm.get('lastName').value || '';
    this.userFormData.email = this.userForm.get('email').value || '';
    this.userFormData.contactNumber =
      this.userForm.get('contactNumber').value || '';
    this.userFormData.roleId = this.userForm.get('roleId').value || '';
    this.userFormData.agency = this.userForm.get('agency').value || '';
    this.submitFormData(formData);
  }

  //upsert userForm data of Onboard & Edit user popUp
  submitFormData(form: any) {
    this.userFormData.agency.trim().replace(/\s+/g, ' ');
    if (form) {
      const reqParams = {
        ...this.userFormData,
        isNewUser: this.isNewUser
    };
      let queryCall: any ;
      let msg: any;
      //  this.messageService.add({severity:'success', summary:'Success!', detail:'User created successfully'});
      if (this.recordId) {
        const reqParams1 = {
          id: this.recordId,
          firstName: this.userFormData.firstName,
          lastName: this.userFormData.lastName,
          email: this.userFormData.email,
          contactNumber: this.userFormData.contactNumber,
          active: this.ischecked,
          roleId: this.userFormData.roleId,
          agency: this.userFormData.agency,
        };
        msg = 'User updated successfully!';
        // this.messageService.add({severity:'success', summary:'Success!', detail:'User updated successfully'});
        queryCall = this.restService.put(
          API_KEYPOINT.user.editUser,
          reqParams1
        );
      }
      else{
        queryCall =  this.restService.post(API_KEYPOINT.user.createUser,reqParams);
        msg = 'User created successfully!';
      }
      queryCall.subscribe({
        next: (data: any) => {
          // alert(msg);
          this.isOnboardModalShow = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Success!',
            detail: msg,
          });
          this.refreshList.emit(true);
        },
        error: (err: any) => {
          this.isOnboardModalShow = true;
          // alert(err.error.message);
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: err.error.message,
          });
        },
      });
    } else {
      setTimeout(function () {
        if (document.querySelector('.required')) {
          document.querySelector('required')?.scrollIntoView(true);
          window.scrollBy(0, -150);
        }
      }, 100);
    }
  }
  
  checkUserEmail(email: string) {
    const param = {
      email: email,
    };
    this.restService
      .post(API_KEYPOINT.client.checkEmailExistForSameClient, param)
      .subscribe({
        next: (data: any) => {
          if (data.code == 200) {
            const res = data.message;
            if (res == 'RESTORE') {
              // this.msg = data.result.msg 
              this.cancelPopUp = true;
            }

          }
        },
      });

  }
  checkEmail() {
    const emailControl = this.userForm.get('email');
    if (emailControl && emailControl.valid) {
      const emailValue = emailControl.value;
      this.checkUserEmail(emailValue);

    }
  }

  /* This user was previously removed from the DAM system. Do you want to restore this user & their associated data?

    if user select yes this means that user want to restore the data 
    in that case we have to send the flag as false 
    "isnewUser : false "

    if user selects no this means that he want to delete older data and create a fresh user
    in that case  we have to send the flag as true 
    "isnewUser : true "


    by default we are restoring the data so isnewUser is bydefault false 
    "isnewUser : false "

   */
  yesEmailPopup() {
    //
    this.isNewUser = false;
    this.cancelPopUp = false;
  }
  noEmailPopUp() {
    this.isNewUser = true;
    this.cancelPopUp = false;
  }
}

