import {
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { AppDataService, PermissionsEngine, RestService } from 'src/app/core/services';
import { MessageService } from 'primeng/api';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/core/services';
import {
  IFolderDetails,
} from 'src/app/common/utils/folderMgmtUtill';
import { AuthService } from 'src/app/core/services/auth.service';
import { API_KEYPOINT } from 'src/app/core/constants';
import { Subscription } from 'rxjs';

//Interfaces for creating root folder
interface IFolder {
  folderId: Number;
  parentId: Number;
  folderName: String;
  folderPath: String | null;
  folderAccess: Array<IFolderAccess>;
} //IFolder

interface IFolderAccess {
  assignedTo: Number;
  roleId: Number;
  accessType: Number;
} //IFolderAccess

interface IAccessType {
  id: Number;
  name: String;
}

interface Iuser {
  id: Number;
  roleId: Number;
  firstName: String;
  lastName: String;
  roleName: String;
  email: String;
  contactNumber: String;
  active: Boolean;
  profession: String;
  isDisabled: Boolean;
}

@Component({
  selector: 'app-create-folder',
  templateUrl: './create-folder.component.html',
  styleUrls: ['./create-folder.component.scss'],
})
export class CreateFolderComponent {
  firstItem: IFolderDetails = {
    firstName: 'First',
    lastName: 'Folder',
    userId: 1,
    folderCount: '',
    assignedCount: '',
    parentId: 0,
    assetCounts: '',
    untaggedAssetsCount: '',
    roleId: 1,
    folderName: '',
    folderId: 0,
    isTaged: 0,
    createdAt: '',
    createByFirstname: '',
    createByLastname: '',
    accessType: 2,
    isCollapse: false,
    isFolderRightClick: false,
    children: [],
  };
  isCreateModalShow: boolean = false;
  creareSubfolderForm: any;
  isRootCreateFolderModalShow: boolean = false;
  handleButtonClicked: boolean = false;
  folderAccessList: any = [];
  parentFolderList: any = [];
  userDetails: any;
  userId: any;
  isDisabled: boolean = false;
  accessTypeList: Array<IAccessType> = [
    { id: 1, name: 'Read' },
    { id: 2, name: 'Read-Upload' },
    { id: 3, name: 'Read-Write-Upload' },
    { id: 4, name: 'Read-Write-Upload-Delete' },
  ];
  userList: Array<Iuser> = [];
  //variables for create Root Folder dialog box
  folder: IFolder = {
    folderId: 0,
    folderName: '',
    parentId: 0,
    folderAccess: new Array<IFolderAccess>(),
    folderPath: '',
  };
  @Input() childFolderId: any;
  @Input() folderDetails: any;
  @Input() objectForSourceTrack: any;
  @Input() folderType: any;
  @Output() triggerMethod = new EventEmitter();
  isEditMode: any = 'add';
  private subscription: Subscription;
  originalValue: any;
  isFormDirty: boolean = false;
  constructor(
    public permissionsEngine: PermissionsEngine,
    private messageService: MessageService,
    private route: ActivatedRoute,
    public appService: AppService,
    public authService: AuthService,
    private restService: RestService,
    private AppDataService: AppDataService

  ) {
    this.userDetails = this.authService.getAuthData();
    this.creareSubfolderForm = new FormGroup({
      folderPath: new FormControl(),
      folderName: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100),
        Validators.pattern('^[a-zA-Z0-9_-]+( [a-zA-Z0-9_-]+)*$'),
      ]),
      parentId: new FormControl(''),
      createdBy: new FormControl(),
      folderAccess: new FormControl(this.folderAccessList),
    });
  }
  ngOnChanges() {
    switch (this.folderType) {
      case 'sub':
        this.isCreateModalShow = true;
        this.getParentFolderAccess(this.objectForSourceTrack.folderId);
        break;
      case 'root':
        this.isRootCreateFolderModalShow = true;
        this.openRootCreateFolderModal();
        break;
    }
  }
  ngOnInit() {
    this.subscription =
      this.AppDataService.currentfolderDataCreation.subscribe((param) => {
        if (param && param.type) {
          this.isEditMode = param.type;
          if (this.isEditMode === 'edit' && param.data?.folderId) {
            this.folder.folderId = param.data.folderId;
          }
          this.openRootCreateFolderModal();
        }
      });

  }
  async callInitItem(folderId?: any) {
    this.restService.get(API_KEYPOINT.folder.folderInfoPatterByFolderId + folderId)
      .subscribe((data: any) => {
        this.folder = { ...this.folder, ...data.result };
        this.folder.folderAccess = this.folder.folderAccess.filter(
          (item: any) => (item.roleId !== 1 && item.roleId !== 2)
        );
        this.userList.forEach((obj: any) => {
          this.folder.folderAccess.forEach((fobj: any) => {
            if (obj.id == fobj.assignedTo) {
              obj.isDisabled = true;
            }
          });
        });
      });
    this.originalValue = { ...this.folder };
  }

  getAllUserList() {
    this.restService
      .get(API_KEYPOINT.user.getUserList)
      .subscribe((data: any) => {
        this.userList = data.result;
        this.userList.forEach((obj: any) => {
          // TaskId-> 165541 if user is admin or superadmin then that user will be disabled and while creating new folder , that folder will be assigned to itself with all-access by default
          obj.isDisabled =
            (this.userDetails.roleId === 1 ||
              this.userDetails.roleId === 2 ||
              this.userDetails.roleId === 4 ||
              this.userDetails.roleId === 6) &&
            obj.id === this.userDetails.userId;
        });
        this.userList = this.userList.filter((user) => (user.roleId != 1 && user.roleId != 2));
      });
    if (this.isEditMode === 'edit') {
      this.callInitItem(this.folder.folderId);
    }
  }

  //============= Open create folder modal  =========//
  openCreateModal() {
    this.isCreateModalShow = true;
    this.getParentFolderAccess(this.objectForSourceTrack.folderId);
  }
  //============= close create folder modal  =========//
  closeCreateModal() {
    this.isCreateModalShow = false;
    let params = { fileDetails: this.objectForSourceTrack };
    this.triggerMethod.emit(params);
    this.creareSubfolderForm.reset();
  }
  closeRootCreateModal(frmFolder: any) {
    frmFolder.resetForm();
    this.isRootCreateFolderModalShow = false;
    this.isFormDirty = false;
    //setting the isDisabled property to false for the userList Array
    this.userList.forEach((item: any) => {
      item.isDisabled = false;
    });
    this.folder.folderAccess = [{} as IFolderAccess];
    this.AppDataService.changeFolderCreation({});
    let params = { fileDetails: this.objectForSourceTrack, cancelRoot: true };
    this.triggerMethod.emit(params);
  }
  //============= create folder api  =========//
  createSubfolder(frmFolder: any) {
    this.handleButtonClicked = true;
    this.creareSubfolderForm.value.folderName =
      this.creareSubfolderForm.value.folderName.trim().replace(/\s+/g, ' ');
    this.creareSubfolderForm.value.parentId = this.objectForSourceTrack.folderId;
    this.creareSubfolderForm.value.folderPath =
      this.folderDetails.id_path.substring(
        0,
        this.folderDetails.id_path.length - 1
      ) + '/';
    this.parentFolderList.filter((el: any) => {
      this.folderAccessList.push({
        assignedTo: el.assignedTo,
        roleId: el.roleId,
        accessType: el.accessType,
      });
    });
    this.creareSubfolderForm.value.folderAccess = this.folderAccessList;
    if (frmFolder.valid) {
      this.restService
        .post(API_KEYPOINT.folder.folderCreate, this.creareSubfolderForm.value)
        .subscribe({
          next: (data: any) => {
            if (data.code === 200) {
              this.isCreateModalShow = false;
              this.creareSubfolderForm.reset();
              if (this.objectForSourceTrack.folderId === 0) {
                let params = {
                  fileDetails: this.objectForSourceTrack,
                  createroot: 'root',
                };
                this.triggerMethod.emit(params);
              } else {
                let params = {
                  fileDetails: this.objectForSourceTrack,
                  create: 'create',
                };
                this.triggerMethod.emit(params);
              }
              this.messageService.add({
                severity: 'success',
                summary: 'Successfull!!',
                detail: data.message,
              });
              this.handleButtonClicked = false;
            }
          },
          error: (err: any) => {
            this.isCreateModalShow = false;
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning!',
              detail: err.error.message,
            });
            this.handleButtonClicked = false;
            let params = { fileDetails: this.objectForSourceTrack, cancelRoot: true };
            this.triggerMethod.emit(params);
          },
        });
      this.folderAccessList = [];
    } else {
      setTimeout(function () {
        if (document.querySelector('.required')) {
          document.querySelector('required')?.scrollIntoView(true);
          window.scrollBy(0, -150);
        }
      }, 100);
    }
  }
  openRootCreateFolderModal() {
    this.getAllUserList();
    this.folder.folderAccess = [{} as IFolderAccess];
    //If admin or super admin is creating the rootfolder then that folder will be by default assigned to itself. TaskId-> 165541
    if (this.userDetails.roleId == 1 || this.userDetails.roleId == 2 || this.userDetails.roleId == 6) {
      if (this.folder.folderAccess.length === 1) {
        this.folder.folderAccess[0] = {
          assignedTo: this.userDetails.userId,
          roleId: this.userDetails.roleId,
          accessType: 4,
        };
      }
    }
    //User Story 176108: Hardcoded Admin should be able to see all folders that are being created in the system
    //& should be able to perform read-write-upload-delete operations on all visible folders
    //added this condition to give self RWU access by default if the user is  media validator
    if (this.userDetails.roleId == 4) {
      //media validator role id  =  4
      if (this.folder.folderAccess.length === 1) {
        this.folder.folderAccess[0] = {
          assignedTo: this.userDetails.userId,
          roleId: this.userDetails.roleId,
          accessType: 3,
        }; //accessType :  3 RWU access
      }
    }
    this.isRootCreateFolderModalShow = true;
  }
  //=========call Folder access api =========//
  getParentFolderAccess(item: any) {
    let folderId = item.folderId ? item.folderId : item;
    if (folderId !== 0) {
      this.restService
        .post(API_KEYPOINT.folder.getParentFolderAccess, {
          folderId: folderId,
        })
        .subscribe((data: any) => {
          if (data.code === 200) {
            this.parentFolderList = data.result;
          }
        });
    }
  }
  //function to disable user when user user keyboard to chnage user
  onKeyDowns(event: FocusEvent, index: number) {
    this.userList.forEach((obj: any) => {
      if (
        obj.id == this.folder.folderAccess[index].assignedTo &&
        event.isTrusted == true
      ) {
        // This condition will enable the previous user in the list when Enter key is pressed
        obj.isDisabled = true;
      }
    });
    event.preventDefault(); // Prevent navigation with arrow keys
  }

  removeUserOnfolderAccessList(index: number) {
    this.userList.forEach((obj) => {
      if (obj.id == this.folder.folderAccess[index].assignedTo) {
        obj.isDisabled = false;
      }
    });
    this.folder.folderAccess.splice(index, 1);
    this.onFormChange();  // Mark form as dirty after removing an item
  } //removeUserOnfolderAccessList
  async addItem() {
    this.folder.folderAccess.push({} as IFolderAccess);
    this.userList.forEach((obj) => {
      this.folder.folderAccess.forEach((fobj) => {
        if (obj.id == fobj.assignedTo) {
          obj.isDisabled = true;
        }
      });
    });
    this.onFormChange();  // Mark form as dirty after removing an item
  } //addItem
  submitFormDataToCreateFolder(frmFolder: NgForm) {
    this.userList.forEach((obj) => {
      this.folder.folderAccess.forEach((fobj) => {
        if (obj.id == fobj.assignedTo) {
          fobj.roleId = obj.roleId;
        }
      });
    });
    this.folder.folderName = this.folder.folderName.trim().replace(/\s+/g, ' ');
    if (frmFolder.form.valid) {
      //set folder path same as Folder Name
      this.folder.folderPath =
        this.folder.folderPath == null || this.folder.folderPath == ''
          ? ''
          : this.folder.folderPath;
      const reqParams = this.folder;

      let queryCall = this.restService.post(
        API_KEYPOINT.folder.folderCreate,
        reqParams
      );
      let msg = '';
      queryCall.subscribe({
        next: (data: any) => {
          if (data.code == 200) {

            if (this.isEditMode === 'edit') {
              msg = 'Folder edited successfully!';
            } else {
              msg = data.message;
            }
            let params = {
              fileDetails: this.objectForSourceTrack,
              createroot: 'root',
            };
            this.triggerMethod.emit(params);
            this.isRootCreateFolderModalShow = false;
            this.isFormDirty = false;  // Reset dirty state after submission
            frmFolder.resetForm();
            this.messageService.add({
              severity: 'success',
              summary: 'Success!',
              detail: data.message,
            });
          }
        },
        error: (err) => {
          frmFolder.resetForm();
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: err.error.message,
          });
          this.isRootCreateFolderModalShow = false;
          let params = { fileDetails: this.objectForSourceTrack, cancelRoot: true };
          this.triggerMethod.emit(params);
        },
      });
      //setting the isDisabled property to false for the userList Array
      this.userList.forEach((item: any) => {
        item.isDisabled = false;
      });
      this.folder.folderAccess = [{} as IFolderAccess];
      this.AppDataService.changeFolderCreation({});

    } else {
      setTimeout(function () {
        if (document.querySelector('.required')) {
          document.querySelector('required')?.scrollIntoView(true);
          window.scrollBy(0, -150);
        }
      }, 100);
    }
  } //submitFormData
  changeUser(event: any, index: number) {
    this.userList.forEach((obj: any) => {
      if (
        obj.id == this.folder.folderAccess[index].assignedTo &&
        event.pointerId == 1
      ) {
        //This condition will enable the previous user in the list
        obj.isDisabled = false;
      } else if (
        obj.id == this.folder.folderAccess[index].assignedTo &&
        event.pointerId == 0
      ) {
        //This will disable the new selected user in the list
        obj.isDisabled = true;
      }
    });
  } //changeUser
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.isRootCreateFolderModalShow = false;
    this.isCreateModalShow = false;
    this.isFormDirty = false;
  }
  // Called whenever form value changes
  onFormChange() {
    this.isFormDirty = JSON.stringify(this.folder) !== JSON.stringify(this.originalValue);
  }
}
