import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { delay, map, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { globals } from '../constants/globals';
import { UserDataService } from './user-data.service';
import { environment } from 'src/app/environment/environment';
import { LocalStorageService } from './local-storage.service';
import { MenuItems } from 'src/app/common/menu-items/menu-items';
import * as CryptoJS from 'crypto-js';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable()
export class AuthService {
  private fetchMenuEndpoint = '/callack/userPrefrenece';
  isSSOUser = false;
  public key = environment.secretKey;
  dummyData = {
    users: {
      active: true,
      name: 'User',
      checked: true,
      groupBy: 'manage',
      subFeatures: {},
      actions: {
        '1': {
          active: true,
          label: 'View',
          checked: false,
        },
        '2': {
          active: true,
          label: 'Create',
          checked: false,
        },
        '3': {
          active: true,
          label: 'Edit',
          checked: false,
        },
        '4': {
          active: true,
          label: 'Delete',
          checked: false,
        },
      },
    },
    roles: {
      active: true,
      name: 'Roles',
      checked: true,
      groupBy: 'manage',
      subFeatures: {},
      actions: {
        '1': {
          active: true,
          label: 'View',
          checked: false,
        },
        '2': {
          active: true,
          label: 'Create',
          checked: false,
        },
        '3': {
          active: true,
          label: 'Edit',
          checked: false,
        },
        '4': {
          active: true,
          label: 'Delete',
          checked: false,
        },
      },
    },
    groups: {
      active: true,
      name: 'Groups',
      checked: true,
      groupBy: 'manage',
      subFeatures: {},
      actions: {
        '1': {
          active: true,
          label: 'View',
          checked: false,
        },
        '2': {
          active: true,
          label: 'Create',
          checked: false,
        },
        '3': {
          active: true,
          label: 'Edit',
          checked: false,
        },
        '4': {
          active: true,
          label: 'Delete',
          checked: false,
        },
      },
    },
    collections: {
      active: true,
      name: 'Collections',
      checked: true,
      groupBy: 'manage',
      actions: {
        '1': {
          active: true,
          label: 'View',
          checked: false,
          subFeatures: {},
        },
        '5': {
          active: true,
          label: 'Create and Add',
          checked: false,
          subFeatures: {},
        },
        '6': {
          active: true,
          label: 'Download',
          checked: false,
          subFeatures: {},
        },
        '7': {
          active: true,
          label: 'Delete Collection',
          checked: false,
          subFeatures: {},
        },
      },
    },
    events: {
      active: true,
      name: 'Event',
      checked: true,
      groupBy: 'manage',
      actions: {
        '1': {
          active: true,
          label: 'View',
          checked: false,
          subFeatures: {},
        },
        '2': {
          active: true,
          label: 'Create',
          checked: false,
          subFeatures: {},
        },
        '3': {
          active: true,
          label: 'Edit',
          checked: false,
          subFeatures: {},
        },
      },
    },
    tags: {
      active: true,
      name: 'Event',
      checked: true,
      groupBy: 'manage',
      subFeatures: {},
      actions: {
        '1': {
          active: true,
          label: 'View',
          checked: false,
        },
        '3': {
          active: true,
          label: 'Edit',
          checked: false,
        },
      },
    },
    folders: {
      active: true,
      name: 'Folders',
      checked: true,
      groupBy: 'manage',
      actions: {
        '8': {
          active: true,
          label: 'Create Root Folder',
          checked: false,
        },
      },
      subFeatures: {},
    },
    search: {
      active: true,
      name: 'Search',
      checked: true,
      groupBy: '',
      actions: {},
      subFeatures: {},
    },
    dashboard: {
      active: true,
      seqeunce: 1,
      name: 'Dashboard',
      checked: true,
      groupBy: '',
      actions: {},
      subFeatures: {},
    },
    cliplist: {
      active: true,
      name: 'Cliplist',
      checked: true,
      groupBy: '',
      actions: {},
      subFeatures: {},
    },
    category: {
      active: true,
      name: 'Categories',
      checked: true,
      groupBy: '',
      actions: {},
      subFeatures: {},
    },
    faceFinder: {
      active: true,
      name: 'Face Finder',
      checked: true,
      groupBy: '',
      actions: {},
      subFeatures: {},
    },
    image_reverse_search: {
      active: true,
      name: 'Image Reverse Search',
      checked: true,
      groupBy: '',
      actions: {},
      subFeatures: {},
    },
    people_management: {
      active: true,
      name: 'People Management',
      checked: true,
      groupBy: '',
      actions: {},
      subFeatures: {},
    },
    ai_tags: {
      active: true,
      name: 'AI Tags',
      checked: true,
      groupBy: 'ai',
      actions: {},
      subFeatures: {},
    },
    object_detection: {
      active: true,
      name: 'Object Detection',
      checked: true,
      groupBy: 'ai',
      actions: {},
      subFeatures: {},
    },
    text_detection: {
      active: true,
      name: 'Text Detection',
      checked: true,
      groupBy: 'ai',
      actions: {},
      subFeatures: {},
    },
    landmark_detection: {
      active: true,
      name: 'Landmark Detection',
      checked: true,
      groupBy: 'ai',
      actions: {},
      subFeatures: {},
    },
    transcripts_and_insights: {
      active: true,
      name: 'Transcripts & Insights',
      checked: true,
      groupBy: 'ai',
      actions: {},
      subFeatures: {},
    },
  };

  // Permission cache to avoid redundant calls
  public permissionCache: { [key: string]: Boolean } = {};
  
  constructor(
    private http: HttpClient,
    private menuItems: MenuItems,
    private localStorageService: LocalStorageService,
    private router: Router,
    private userData: UserDataService
  ) {
    this.checkExpiration();
  }
  
  clearPermissionCache() {
    this.permissionCache = {};
  }

  loginForgotPassword(formdata: any, url?: any) {
    formdata.url = url;
    return this.http.post(
      `${environment.apiUrl}login/forgotPassword`,
      formdata,
      httpOptions
    );
  }

  getRoles() {
    // let token='';
    // let http_opj=new HttpHeaders().set("Authorization","bearer"+token)
    return this.http.get(`${environment.apiUrl}master/getRoles`);
  }

  // login(payload: any) {
  //   return this.http
  //     .post(`${environment.apiUrl}login`, payload)
  //     .pipe(mergeMap((response) => this.validateAuthResponse(response)));
  // }

  login(payload: any) {
    return this.http.post(`${environment.apiUrl}login`, payload, httpOptions);
  }

  validateAuthResponse(resp: any, isSSOUser?: any) {
    this.isSSOUser = isSSOUser;
    if (resp.Validate === 'TRUE') {
      if (resp.NewDeviceOTP && resp.SendOTP) {
        this.generateOTP(resp.TokenId).subscribe();
        if (this.getAuthData()) {
          this.logout();
        } else {
          throw new Error('NewDeviceDetected' + '__' + resp.TokenId);
        }
      } else if (resp.CompanyList.length > 0) {
        this.userData.setUserFromAuthResponse(resp);
        return this.http
          .post(this.fetchMenuEndpoint, {
            UserId: this.userData.getUserInfo().UserId,
            CompId: this.userData.getSelectedCompany().CompanyId,
          })
          .pipe(map((response) => this.processUserSettings(response)))
          .pipe(delay(200));
      }
    }
    throw new Error(resp.Message || 'Invalid User Details!');
  }

  emailLookup(email: any) {
    return this.http.post(`${environment.apiUrl}login/getAuthListByEmail`, {
      email: email,
    });
  }

  authenticatePassword(payload: any) {
    return this.http.post(
      `${environment.apiUrl}login/authenticatePassword`,
      payload
    );
  }

  getUserClients(payload: any, customHeader?: any) {
    const url = `${environment.apiUrl}login/getClientListAsPerAuth`;
    const options = customHeader ? { headers: new HttpHeaders({ Authorization: `Bearer ${customHeader}` }) } : {};
    return this.http.post(url, payload, options);
  }

  verifyOTP(payload: any) {
    return this.http.post(`${environment.apiUrl}login/validateOtp`, payload);
  }

  generateOTP(userId: any) {
    return this.http
      .post(`${environment.apiUrl}login/generateOTPForLogin`, {
        emailId: userId,
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  processUserSettings(userSettings: any) {
    if (userSettings) {
      // this.userData.setUserPreference(userSettings.userpreferencev2);
      // this.menuItems.setMenuList(userSettings.fetchmenuv2.main);
    }
  }

  fetchUserSettings() {
    return this.http
      .post(this.fetchMenuEndpoint, {
        UserId: this.userData.getUserInfo().UserId,
        CompId: this.userData.getSelectedCompany().CompanyId,
      })
      .pipe(map((response) => this.processUserSettings(response)));
  }

  setSession(data: any) {
    const expiration = new Date();
    expiration.setDate(expiration.getDate() + 1);
    const userData = {
      data: this.encrypt(data),
      expiration: expiration.getTime(),
    };
    this.localStorageService.addItem(
      globals.sessionCookie,
      JSON.stringify(userData)
    );
  }

  public encrypt(txt: string): any {
    return CryptoJS.AES.encrypt(JSON.stringify(txt), this.key).toString();
  }

  public decrypt(txtToDecrypt: string) {
    const bytes = CryptoJS.AES.decrypt(txtToDecrypt, this.key);
    try {
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (err) {
      return null;
    }
  }

  getAuthData() {
    if (this.localStorageService.getItem(globals.sessionCookie) != null) {
      const userDataObj = JSON.parse(
        this.localStorageService.getItem(globals.sessionCookie) || ''
      );
      if (userDataObj && userDataObj.data) {
        return this.decrypt(userDataObj.data);
      }
    }
  }

  clearSession() {
    this.localStorageService.clear();
    this.clearPermissionCache();
  }

  public isLoggedIn(): boolean {
    const user = this.localStorageService.getItem(globals.sessionCookie);
    return !!user;
  }

  // Check expiration and clear user data if expired
  private checkExpiration() {
    const userData = this.localStorageService.getItem(globals.sessionCookie);
    if (userData) {
      const userDataObj = JSON.parse(userData);
      if (Date.now() >= userDataObj.expiration) {
        this.clearSession();
      }
    }
  }

  logout(redirectToWinfdow?: any) {
    this.clearSession();
    if (redirectToWinfdow) {
      window.location.href = redirectToWinfdow;
    } else {
      this.router.navigate(['/auth/login']);
    }
  }

  loginWithSso(providerName: string) {
    if (providerName === 'google') {
      return this.http.get(`${environment.apiUrl}login/googleAuth`);
    } else {
      return new Observable((observer) => {
        observer.error();
      });
    }
  }

  doSSOLogout({ providerName = 'saml', redirectRoute = 'auth/login' }) {
    // return this.http.post(
    //   `${environment.apiUrl}logout/${providerName}?redirectUri=${
    //     environment.HOST_URL + redirectRoute
    //   }`,
    //   {}
    // );
    return this.http.post(`${environment.apiUrl}logout/${providerName}`, {});
  }

  doSSOLogin({
    providerName = 'saml',
    redirectRoute = 'auth/login',
    queryParams = {
      email: '',
      hostInfo: '',
    },
    externalIntegrationParams = {
      google: false,
      googleRedirectURI: '',
    },
  }) {
    return this.http.get(
      `${environment.apiUrl}login/${providerName}?redirectUri=${
        environment.HOST_URL + redirectRoute
      }${queryParams.email ? '&email=' + queryParams.email : ''}${
        queryParams.hostInfo ? '&_host_Info=' + queryParams.hostInfo : ''
      }${externalIntegrationParams.google ? '&google=true' : ''}${
        externalIntegrationParams.googleRedirectURI
          ? '&googleRedirectURI=' +
            encodeURIComponent(externalIntegrationParams.googleRedirectURI)
          : ''
      }`
    );
  }
}
