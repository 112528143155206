import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import { AppService } from 'src/app/core/services/app.service';
import { MessageService } from 'primeng/api';
import { EventData, EventEmitterService, RestService } from 'src/app/core/services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormatS3UrlPipe } from '../../pipes';
import { API_KEYPOINT, APP_EVENTS } from 'src/app/core/constants';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-video-trim',
  templateUrl: './video-trim.component.html',
  styleUrls: ['./video-trim.component.scss'],
})
export class VideoTrimComponent {
  @ViewChild('video') videoElement: ElementRef<HTMLVideoElement> | undefined;
  @ViewChild('video') video!: ElementRef<HTMLVideoElement>;
  @Input('source') source!: string;
  @Input() isTrimclicked: any;
  @Input() isTrimVideo: any;
  @Input('assetName') assetName!: string;
  @Input('assetPath') assetPath!: string;
  @Input('referenceAssetId') referenceAssetId!: string;
  @Input('assetType') assetType!: string;
  @Input('folderId') assetfolderId!: string;
  @Output() showPreview = new EventEmitter();
  @Output() isvideoeditorclosed = new EventEmitter();
  private mediaSource = new MediaSource();
  previewFile: any;
  private initTime = 0;
  public lastTime = 300;
  currentTime: number;
  private sourceBuffer: any;
  trimsliderPreview: boolean = true;
  duration = 0;
  IspreviewShow: boolean = false;
  IsVideoSave: any = 0;
  trimAssetId: any;
  videoUrl: any = '';
  videoType: any = '';
  formAssetsName: any = '';
  videoForm: any = '';
  formatS3UrlPipe = new FormatS3UrlPipe();
  subscription: Subscription [] = [];
  private eventSubscription: Subscription | undefined;

  constructor(
    public appService: AppService,
    private messageService: MessageService,
    private restService: RestService,
    private eventEmitterService: EventEmitterService,
    private router: Router
  ) {
    /* Add Reactive Form for trim video validation */
    this.videoForm = new FormGroup({
      videoName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
        Validators.minLength(2),
      ]),
    });
  }

  ngOnInit() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    window.addEventListener('unload', this.handleUnload); // Trigger API only when leaving
    this.videoForm.controls['videoName'].enable();
    this.videoUrl = this.formatS3UrlPipe.transform(
      this.appService.s3BaseUrl + this.assetPath + '/' + this.assetName
    );
    this.videoType = this.videoUrl.substring(
      this.videoUrl.lastIndexOf('.') + 1
    );
    if (parseInt(this.assetType) === 1) {
      this.formAssetsName = 'Video Name';
    } else {
      this.formAssetsName = 'Audio Name';
    }  
    this.subscribeToEvent();
    this.subscription.push(this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Re-subscribe to the event if necessary when navigation ends
        if (!this.eventSubscription || this.eventSubscription.closed) {
          this.subscribeToEvent();
        }
      }
    }));
  }

  subscribeToEvent(): void {
    this.eventSubscription = this.eventEmitterService.subscribe((event: EventData) => {
      if (event.type === APP_EVENTS.BROWSER_BACK) {
        if (this.IspreviewShow) {
          this.closePreviewModal();
        }
      }
    });
    this.subscription.push(this.eventSubscription);
  }

  ngAfterViewInit() {
    this.mediaSource.addEventListener('sourceopen', this.handleSourceOpen, false);

    this.video.nativeElement.ontimeupdate = () => {
      if (this.lastTime && this.video.nativeElement.currentTime >= this.lastTime) {
        this.video.nativeElement.pause();
        this.video.nativeElement.currentTime = this.initTime;
      }
    };

    this.video.nativeElement.onloadeddata = () => {
      this.duration = Math.round(this.video.nativeElement.duration - 0.5);
    };
  }


  secondsToHms(d: number): string {
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = d % 60;

    return [h, m, s]
      .map(unit => unit.toString().padStart(2, '0'))
      .join(':');
  }


  setTimeInit(value: any) {
    if (this.video && this.video.nativeElement.duration) {
      this.videoForm.controls['videoName'].reset()
      this.initTime = value;
      this.video.nativeElement.currentTime = this.initTime;
    }
  }

  setTimeLast(value: any) {
    let timeFinish = value;
    this.lastTime = timeFinish;
    let videosDummyName = this.assetName.slice(0, 76).split('.')[0];
    const initialText =
      videosDummyName +
      '_' +
      this.secondsToSpecifiedFormat(this.initTime) +
      '_' +
      this.secondsToSpecifiedFormat(this.lastTime);
    this.videoForm.controls['videoName'].setValue(initialText);
  }

  handleSourceOpen() {
    this.sourceBuffer = this.mediaSource.addSourceBuffer(
      'video/webm; codecs="vp8"'
    );
  }

  submitFormData(formVideo: any): void {
    this.trimsliderPreview = false;

    if (!formVideo.valid) return;

    const { s3BaseUrl } = this.appService;
    const { currentTime } = this.video.nativeElement;
    const videoUrl = this.formatS3UrlPipe.transform(`${s3BaseUrl}${this.assetPath}/${this.assetName}`);

    const myparams = {
      trimmedAssetName: this.videoForm.value.videoName,
      folderId: this.assetfolderId,
      referenceAssetId: Number(this.referenceAssetId),
      videoUrl,
      videoStartTime: this.secondsToHms(currentTime),
      videoDuration: this.lastTime - currentTime,
    };

    this.videoForm.controls['videoName'].disable();

    this.restService.post(API_KEYPOINT.assets.trimVideo, myparams).subscribe({
      next: this.handleSuccess.bind(this),
      error: this.handleError.bind(this),
    });
  }

  private handleSuccess(res: any): void {
    if (res.code === 200) {
      const { id, assetPath, assetName, assetPresignedUrl } = res.result;

      this.IsVideoSave = 1;
      this.trimAssetId = id;
      this.previewFile = assetPresignedUrl;
      this.videoPreview();
    }
  }

  private handleError(err: any): void {
    this.trimsliderPreview = true;
    this.videoForm.controls['videoName'].enable();
    this.previewFile = '';
    this.videoForm.reset();
    this.messageService.add({
      severity: 'error',
      summary: 'Error!',
      detail: err.error.message,
    });
  }

  videoPreview() {
    this.IspreviewShow = true;
  }

  closePreviewModal() {
    this.trimsliderPreview = true;
    this.IspreviewShow = false;

    if (this.previewFile) {
      this.videoForm.controls['videoName'].enable();

      const assetArray = [{
        assetId: this.trimAssetId,
        folderId: this.assetfolderId,
      }];

      const param: any = {
        assetArr: assetArray,
      };

      this.restService
        .post(API_KEYPOINT.assets.assetDelete, param)
        .subscribe({
          next: (data: any) => {
            if (data.code === 200) {
              this.previewFile = '';
            }
          },
          error: (err) => { },
        });
    }
    this.videoForm.reset();
    this.video.nativeElement.currentTime = 0;
    this.lastTime = 0;
  }

  saveCroppedVideo(): void {
    this.trimsliderPreview = true;
    document.body.classList.remove('p-overflow-hidden');

    const msg = parseInt(this.assetType) === 1
      ? 'Video trimmed successfully!'
      : 'Audio trimmed successfully!';

    this.messageService.add({
      severity: 'success',
      summary: 'Success!',
      detail: msg,
    });

    this.showPreview.next(false);
    this.resetPreviewState();
  }

  private resetPreviewState(): void {
    this.IspreviewShow = false;
    this.previewFile = '';
    this.isvideoeditorclosed.emit(true);
  }

  cancelVideo(videoForm: any) {
    this.isvideoeditorclosed.emit(true);
    if (this.previewFile) {
      this.videoForm.controls['videoName'].enable();
      this.IspreviewShow = false;
      this.showPreview.next({
        trimAssetId: this.trimAssetId,
        IsVideoSave: this.IsVideoSave,
      });
    } else {
      this.videoForm.controls['videoName'].enable();
      this.showPreview.next(false);
    }
  }

secondsToSpecifiedFormat(seconds: number): string {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);
 
  //padStart is used to automatically add a leading zero if necessary

  const hDisplay = h.toString().padStart(2, '0');
  const mDisplay = m.toString().padStart(2, '0');
  const sDisplay = s.toString().padStart(2, '0');
  const mSDisplay = '00'; // Fixed millisecond display

  return `${hDisplay}:${mDisplay}:${sDisplay}:${mSDisplay}`;
}

valueChange(event: any){
  switch (event.type) {
    case 'rangeValues':
      this.setTimeInit(event.data.minValue);
      this.setTimeLast(event.data.maxValue);
      break;
    default:
      break;
  }
}

// Function to handle browser/tab close event
handleBeforeUnload = (event: BeforeUnloadEvent) => {
  // Optionally prompt user before closing the tab
  event.preventDefault();
}

handleUnload  = (event: Event) => {
  this.closePreviewModal();
}

ngOnDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    window.removeEventListener('unload', this.handleUnload);
    if (this.IspreviewShow) {
      this.closePreviewModal();
    }
    this.subscription.forEach((s)=> s.unsubscribe());
  }
}
