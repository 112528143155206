<div class="filemanagercntr sticky-top-bar">
  <div class="w-100 position-relative">
    <div class="navcntr d-flex justify-content-between position-absolute border-0 custome_context_menu_position ps-3">
      <div class="d-flex align-items-center flex-wrap" role="tablist">
        <!-- BUG-165672 Create Sub Folder with Folder Access End-->
        <ul class="upload-sec dropdown-menu show bg-transparent d-flex position-static p-0 border-0 flex-wrap my-0"
          *ngIf="hasUploadPerm">
          <div class="d-flex align-items-center font-medium">
            <div class="dropdown" *ngIf="isCollapse && isAssetView">
              <button class="btn btn-primary d-btn-primary btn-upload ps__fs" type="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                <img src="./assets/images/icons/icon-plus.svg" class="p-1" height="20px" width="20px" alt="plus icon">Upload
              </button>
              <ul class="dropdown-menu">
                <li>
                  <a class="dropdown-item cursorpointer" (click)="openAssetsModal('image')"><span class="me-3">
                      <img src="/assets/images/pages/context-menu/ic_upload_asset.svg" alt="upload">
                    </span>Upload</a>
                </li>
                <li>
                  <a class="dropdown-item cursorpointer" (click)="openAssetsModal('zip')"><span class="me-3">
                      <img src="/assets/images/pages/context-menu/ic_zip_upload.svg" alt="zip_upload">
                    </span>Zip Upload</a>
                </li>
              </ul>
            </div>
          </div>
        </ul>
        <ul *ngIf="
            showAllAssetsActionButtons === true &&
            selectedAssets &&
            selectedAssets.length
          "
          class="viewrapper dropdown-menu show bg-transparent border-0 d-flex position-static p-0 d-flex align-items-center flex-wrap list-items-wrapper">
          <li *ngIf="bulkTagAccess">
            <button class="dropdown-item" *ngIf="
                selectedAssets &&
                selectedAssets.length > 1
              " (click)="menuSelection('bulkTagAsset')">
              <div class="d-flex align-items-center font-medium">
                <i class="nm_icon me-2">
                  <img src="assets\images\icons\tag-icon.svg" style="height: 16px;" class="me-2">
                </i>
                <span class="icon-heading">Bulk Tag</span>
              </div>
            </button>
          </li>
          <li>
            <button class="dropdown-item" (click)="[
                multiSharePopUpIsOpen == false
                  ? (multiSharePopUpIsOpen = true)
                  : (multiSharePopUpIsOpen = false), checkingForPreSignedUrl()]
              " [disabled]="selectedAssets.length <= 1" *ngIf="
                selectedAssets &&
                selectedAssets.length > 1
              ">
              <div class="d-flex align-items-center font-medium">
                <i class="nm_icon me-2">
                  <img src="/assets/images/icons/ic_share-asset.svg.svg" alt="share_icon">
                </i>
                <!-- // Bug-Fix-122377 :: DAM : Admin : Share pop up does not disappears when we move image from one folder to another. -->
                <span class="icon-heading" #toggleMultiShareButton>Share</span>
              </div>
            </button>
            <!-- Share Multiple Selected Assets PopUp -->
            <div class="custom-multishare-wrapper" *ngIf="Checkmultipleseledcted()" [ngClass]="{
                'openSharePopUp py-0': multiSharePopUpIsOpen,
                closeSharePopUp: !multiSharePopUpIsOpen
              }" (click)="
                [
                  multiSharePopUpIsOpen == false
                    ? (multiSharePopUpIsOpen = true)
                    : (multiSharePopUpIsOpen = false)
                ]
              ">
              <share-buttons [theme]="'circles-dark'" [include]="['whatsapp']" [show]="3"
                [url]="getAssertUrlsShareMultipleSelectedAssets()" [title]="'Share'" (click)="saveShareLink()">
              </share-buttons>
              <span class="gdrive" (click)="ShareMultipleSelectedAssetsOnGdrive()" (click)="saveShareLink()">
                <img src="/assets/images/icons/ic_google_drive.svg" style="height: 24px;" alt="google_drive">
              </span>
              <span class="copy-link" (click)="ShareMultipleSelectedAssetsOnMail()" (click)="saveShareLink()">
                <img src="/assets/images/icons/ic_share_asset_mail.svg" style="height: 20px;" alt="mail">
              </span>
            </div>
          </li>
          <li>
            <button class="dropdown-item" (click)="menuSelection('download')" *ngIf="
                selectedAssets &&
                selectedAssets.length > 1
              ">
              <div class="d-flex align-items-center font-medium fs-16">
                <i class="nm_icon me-2">
                  <img src="assets/images/icons/download.svg" class="wh-icon" />
                </i>
                <span class="icon-heading">Download</span>
              </div>
            </button>
          </li>
          <li>
            <button class="dropdown-item" (click)="clearAllAssests()" *ngIf="selectedAssets && selectedAssets.length > 1">
              <div class="d-flex align-items-center font-medium fs-16">
                <i class="nm_icon me-2">
                  <img src="assets/images/icons/icon-remove-primary.svg" class="me-1" style="height: 20px;"
                    alt="delete">
                </i>
                <span class="icon-heading">Clear</span>
              </div>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!-- dialog box for upload assets -->
<app-upload *ngIf="isUploadModalClose" (closeModalEvent)="closeModalEvent($event)" [childFolderId]="childFolderId"
  [assetPathofFolder]="assetUploadPath" [modalType]="modalType"></app-upload>

<!-- <p-toast></p-toast> -->

<!----------------Modal to tak assets in bulk (BULK TAG)------------------->
<app-bulk-tag *ngIf="isBulkAssetTag==true" [selectedAssets]="selectedAssets" [objectForSourceTrack]="objectForSourceTrack" [assetTag]="assetTag" (closeBulktag)="closeBulkTagModal($event)"></app-bulk-tag>