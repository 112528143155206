import { Injectable } from '@angular/core';
import {
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from '../services/auth.service';
import { APP_ROUTE } from '../constants';

@Injectable()
export class LoginGuard {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    const authData = this.authService.getAuthData();
    if (authData) {
      const targetRoute = authData.clientId
        ? APP_ROUTE.home
        : APP_ROUTE.clients;
      return this.router.createUrlTree([targetRoute]);
    }
    return true;
  }
}
