<div class="main-container no-preview-wrapper"  style="background-color: #DDE5FB;">
    <div class="container h-100" >
      <div class="row h-100 row-cols-1 flex-column justify-content-center align-items-center">
        <div class="col col-md-6 text-center mt-5">
          <span>
            <img src="./assets/images/icons/noView.svg" width="25px" alt="no view image" />
          </span>
        </div>
        <div class="col text-center mt-3 pb-5" style="color: #000000">
          <h6 class="font-bold">Preview Unavailable</h6>
          <p class="py-3 font-regular text-dark" style="font-size: smaller;">
            {{message}}
          </p>
        </div>
      </div>
    </div>
  </div>