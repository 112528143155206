import { Injectable } from '@angular/core';
import { RestService } from 'src/app/core/services';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { LoggerService } from 'src/app/core/services';
import { API_KEYPOINT, APP_ROUTE } from 'src/app/core/constants';
import { AuthService } from 'src/app/core/services/auth.service';

export interface IsubModules {
  subModuleId: Number;
  subModuleName: String;
  subModuleSlug: String;
  isChecked: Boolean;
  isActive: Boolean;
}

export interface IPermissions {
  moduleId: Number;
  moduleName: String;
  moduleSlug: String;
  subModules: Array<IsubModules>;
  isActive: Boolean;
}

export interface IFolderAccess {
  id: Number;
  name: string;
  accessTypeSlug: string;
}

@Injectable()
export class PermissionsEngine {
  private rolePermissionsLoggedInUser: Array<IPermissions> =
    Array<IPermissions>();
  private rolePermissionsDummy: Array<IPermissions> = new Array<IPermissions>();
  private totalFolderCount: number = 0;
  private loggedInUserFolderAssignedList: Array<{
    folderId: Number;
    assignedTo: Number;
    roleId: Number;
    accessType: Number;
  }>;
  public folderAccessType: Array<IFolderAccess> = [
    { id: 1, name: 'Read', accessTypeSlug: 'read' },
    { id: 2, name: 'Read-Upload', accessTypeSlug: 'read_upload' },
    { id: 3, name: 'Read-Write-Upload', accessTypeSlug: 'read_write_upload' },
    {
      id: 4,
      name: 'Read-Write-Upload-Delete',
      accessTypeSlug: 'read_write_upload_delete',
    },
  ];
  constructor(
    private authService: AuthService,
    private router: Router,
    private logger: LoggerService,
    private restService: RestService
  ) {
    console.log('permission constructor');
  }

  public async setup() {
    if (
      this.authService.getAuthData().rolePermissionsLoggedInUser == undefined
    ) {
      this.rolePermissionsDummy = await this.getRolePermissionsDummy(true);
      this.rolePermissionsLoggedInUser =
        await this.getExistingPermissionsByRoleID(
          this.authService.getAuthData()?.roleId
        );
      let data = this.authService.getAuthData();
      data.rolePermissionsDummy = this.rolePermissionsDummy;
      data.rolePermissionsLoggedInUser = this.rolePermissionsLoggedInUser;
      this.authService.setSession(data);
    } else {
      this.rolePermissionsDummy =
        this.authService.getAuthData()?.rolePermissionsDummy;
      this.rolePermissionsLoggedInUser =
        this.authService.getAuthData()?.rolePermissionsLoggedInUser;
    }
    await this.setFolderlistByUserId();
  }

  public async getRolePermissionsDummy(onlyActive: Boolean) {
    let rolePermissionsDummyTemp: Array<IPermissions> = [];
    try {
      await new Promise((resolve, reject) =>
        setTimeout(() => {
          //Logic to get all permission from API
          this.restService
            .post(API_KEYPOINT.permission.getPermissionsMenu, {
              onlyActive: onlyActive,
            })
            .subscribe({
              next: (data: any) => {
                rolePermissionsDummyTemp = data.result;
                resolve(data.result);
              },
              error: (err) => {
                reject(err);
              },
            });
        })
      );
    } catch (error) {}
    return rolePermissionsDummyTemp;
  } //getPermissionDummy

  public async getExistingPermissionsByRoleID(roleId: Number) {
    let rolePermissionsObj: Array<IPermissions> = [];
    try {
      await new Promise((resolve, reject) =>
        setTimeout(() => {
          this.restService
            .post(API_KEYPOINT.permission.getSideMenu, {
              roleId: roleId,
            })
            // this.clientadminService.callGetQuery('', 'role/getRoleDetailsById/', roleId)
            .subscribe({
              next: (data: any) => {
                rolePermissionsObj = data.result;
                rolePermissionsObj.forEach((mod) => {
                  if (mod.subModules == null) mod.subModules = [];
                  else mod.subModules = JSON.parse(mod.subModules.toString());
                });
                resolve(rolePermissionsObj);
              },
              error: (err) => {
                reject(err);
              },
            });
        })
      );
    } catch (error) {}
    return rolePermissionsObj;
  } //getExistingPermissionsByRoleID

  public async setUserAssignedFolderCountByUserId() {
    let requestBody = {
      limit: 1,
      pageno: 1,
      parentId: 0,
      userId: this.authService.getAuthData()?.userId,
      //send null value to get all type folder
      // "folderOrigin": null
    };
    await new Promise((resolve, reject) =>
      setTimeout(() => {
        //hit api and get folder count by userID
        this.restService
          .post(API_KEYPOINT.folder.folderList, requestBody)
          .subscribe({
            next: (data: any) => {
              if (data.code == 200) {
                if (data.totalCount != undefined) {
                  this.totalFolderCount = data.totalCount;
                }
              }
              resolve(data);
            },
            error: (err) => {
              // this.messageService.add({ severity: 'error', summary: 'Please relogin', detail: `FolderAccess Error Say's:: ${err.error.message}` });
              this.logger.log(
                `FolderAccess Error Say's:: ${err.error.message}`
              );
              reject(err);
            },
          });
      })
    );
  } //getUserAssignedFolderCountByUserId

  public async setFolderlistByUserId() {
    //get folder access detail by folderId
    await new Promise((resolve, reject) =>
      setTimeout(() => {
        //hit api and get folder count by userID
        this.restService
          .post(API_KEYPOINT.folder.folderDetailsById, {
            userId: this.authService.getAuthData()?.userId,
          })
          .subscribe({
            next: (data: any) => {
              if (data.code == 200) {
                this.loggedInUserFolderAssignedList = data.result;
              }
              resolve(data);
            },
            error: (err) => {
              // this.messageService.add({ severity: 'error', summary: 'Please relogin', detail: `FolderAccess Error By Folder Id Says: ${err.error.message}` });
              this.logger.log(
                `FolderAccess Error By Folder Id Says: ${err.error.message}`
              );
              reject(err);
            },
          });
      })
    );
  } //setFolderlistByUserId

  getLoggedInUserFolderAssignedList() {
    return this.loggedInUserFolderAssignedList;
  } //getLoggedInUserFolderAssignedList

  //parse Permission Obj
  public permissionsParser(
    rolePermissions: Array<IPermissions>,
    isParsingForDisplay: Boolean
  ): Array<IPermissions> {
    let tempRolePermissions: Array<IPermissions> = [];
    //use this logic for edit Roles Permissions
    //logic will add missing checkbox modules dummy data.
    if (isParsingForDisplay) {
      var tempRolePermissionsMapDummy = new Map<
        String,
        IPermissions | undefined
      >();
      var tempRolePermissionsMap = new Map<String, IPermissions>();
      //convert Array to Map Objects
      this.rolePermissionsDummy.forEach((modPer) => {
        tempRolePermissionsMapDummy.set(modPer.moduleSlug, modPer);
      });
      rolePermissions.forEach((modPer) => {
        tempRolePermissionsMap.set(modPer.moduleSlug, modPer);
      });

      //Operate Module map objects
      tempRolePermissionsMapDummy.forEach((value, key) => {
        if (tempRolePermissionsMap.has(key)) {
          let subModulesMapDummyTemp = new Map<
            String,
            IsubModules | undefined
          >();
          let subModulesMapTemp = new Map<String, IsubModules>();

          //convert Array to Map Objects
          tempRolePermissionsMapDummy.get(key)?.subModules.forEach((obj) => {
            subModulesMapDummyTemp.set(obj.subModuleSlug, obj);
          });
          tempRolePermissionsMap.get(key)?.subModules.forEach((obj) => {
            subModulesMapTemp.set(obj.subModuleSlug, obj);
          });

          //Operate Sub-Module map objects
          subModulesMapDummyTemp.forEach((inVal, inKey) => {
            if (subModulesMapTemp.has(inKey)) {
              subModulesMapDummyTemp.set(inKey, subModulesMapTemp.get(inKey));
            }
          });

          //Re-construct RolePermissions SubModules
          let subModulesArrTemp = new Array<IsubModules>();
          subModulesMapDummyTemp.forEach((inVal, inKey) => {
            subModulesArrTemp.push(
              inVal == undefined ? ({} as IsubModules) : inVal
            );
          });

          var finalObject = Object.assign({}, tempRolePermissionsMap.get(key), {
            subModules: subModulesArrTemp,
          });

          //set Pasrsed Module
          tempRolePermissionsMapDummy.set(key, finalObject);
        }
      });

      //Re-construct RolePermissions
      tempRolePermissions = new Array<IPermissions>();
      tempRolePermissionsMapDummy.forEach((val, key) => {
        tempRolePermissions.push(val == undefined ? ({} as IPermissions) : val);
      });
      return tempRolePermissions;
    } else {
      //use this logic for submit Roles Permissions
      //logic will remove checkbox modules dummy(Empty) data.
      tempRolePermissions = rolePermissions.filter(
        (modPer) =>
          modPer.subModules.filter((subModPer) => subModPer.isChecked).length >
          0
      );
      return tempRolePermissions;
    }
  } //permissionsParser(-)
  //check loggedIn user have specified module and subModule access or not
  public hasPermission(moduleSlug: String, subModuleSlug: String): Boolean {
    //always true for superAdmin
    let roleId = this.authService.getAuthData()?.roleId;
    if (roleId == 1 || roleId == 2) return true;
    if (
      this.rolePermissionsLoggedInUser.length == Array<IPermissions>().length
    ) {
      // fetch permission from DB
      this.setup();
    }
    return (
      this.rolePermissionsLoggedInUser
        .filter((mod) => moduleSlug == mod.moduleSlug)
        .filter(
          (mod) =>
            mod.subModules
              .filter((submod) => submod.subModuleSlug == subModuleSlug)
              .filter((submod) => submod.isChecked == true).length > 0
        ).length > 0
    );
  } // hasPermission

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Boolean {
    let val = this.hasPermission(
      route.data.moduleSlug,
      route.data.subModuleSlug
    );
    // override permission with folder Access
    val = this.overridePermissionWithFolderAccess(state.url, val);
    if (!val) {
      this.router.navigate(['/auth/access-denied']);
    }
    return val;
  }

  public overridePermissionWithFolderAccess(
    urlToCheck: string,
    val: Boolean
  ): Boolean {
    let urlCheck: Array<string> = [APP_ROUTE.allUploads, APP_ROUTE.coreUploads];
    if (this.totalFolderCount > 0) {
      if (urlCheck.filter((obj) => obj == urlToCheck).length > 0) {
        //if url is there so return true
        return true;
      } else {
        //else return current value
        return val;
      }
    } else {
      return val;
    }
  } //overridePermissionWithFolderAccess

  //Instruction:: To use this func we need to pass folderId and minimum folderAccess Object which is required for that action to perform
  // folder access object is folderAccessType from Permission.ts
  //ex:: permissionsEngine.folderAccessOnHasPermission(123, permissionsEngine.folderAccessType[0])
  //check loggedIn user have specified module and subModule access or not
  public folderAccessOnHasPermission(
    folderId: Number,
    minAccessType: IFolderAccess
  ): Boolean {
    let roleId = this.authService.getAuthData()?.roleId;
    //allow super admin to perform all function on any folder & asset
    if (roleId == 1 || roleId == 2) return true;

    let val: Boolean = false;
    if (folderId && this.loggedInUserFolderAssignedList != undefined) {
      let matchingFolderArr = this.loggedInUserFolderAssignedList.filter(
        (folder: any) => folder.folderId == folderId
      );
      if (matchingFolderArr.length > 0) {
        let userFolderAccessTypeId = matchingFolderArr[0].accessType;
        // check fun object have big id than function access id then return false else true
        if (userFolderAccessTypeId > minAccessType.id) {
          val = true;
          // Bug 184635: DAM : Admin : Redirection on clicking on assets present on the global search results screen is faulty
        } else val = userFolderAccessTypeId == minAccessType.id;
      }
    }
    return val;
  } //folderAccessOnHasPermission

  // Helper function to check permissions and cache the result
  checkPermission(moduleSlug: string, subModuleSlug: string): Boolean {
    const key = `${moduleSlug}-${subModuleSlug}`;
    if (!(key in this.authService.permissionCache)) {
      this.authService.permissionCache[key] = this.hasPermission(moduleSlug, subModuleSlug);
    }
    return this.authService.permissionCache[key];
  }

  // Method to check multiple permissions and store them in the component
  checkMultiplePermissions(
    permissions: Array<{ key: string, module: string, subModule: string }>,
    permissionsObject: { [key: string]: Boolean },
  ) {
    permissions.forEach(permission => {
      const permissionKey = permission.key;
      permissionsObject[permissionKey] = this.checkPermission(permission.module, permission.subModule);
    });
  }
} 
