import {  AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { EventData, EventEmitterService, LoggerService } from './core/services';
import { environment } from './environment/environment';
import { APP_EVENTS } from './core/constants';
import { CommonUtils } from 'src/app/common/utils/common.utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'DAM_UI';
  userActivity: any;
  userInactive: Subject<any> = new Subject();
  sessionTime: boolean = false;
  extendFlag: boolean = false;
  timeOutFlag = true;
  checkFlag = true;
  private subscriptions: Subscription[] = [];
  overflow: boolean = true;
  hideHeader: boolean = false;
  commonUtils = new CommonUtils();
  isLoggedOut: boolean = false;

  constructor(
    private logger: LoggerService,
    private router: Router,
    private eventEmitterService: EventEmitterService,
  ) {
    // window.addEventListener('storage', (event) => {
    //   if (event.key === 'logout-event' && event.newValue === 'logout') {
    //     this.router.navigate(['/auth/login']);
    //     localStorage.clear();
    //   }
    // });
  }

  ngOnInit() {
    this.subscriptions.push(
      this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this.commonUtils.scrollToAppMain();
        } else if (event.navigationTrigger === 'popstate') {
          this.eventEmitterService.emit({
            type: APP_EVENTS.BROWSER_BACK,
            data: null,
          });
        }
      }),
    );
    this.logger.setLogging(environment.logsEnabled);
  }

  ngAfterViewInit(): void {
    this.eventEmitterService.subscribe((event: EventData) => {
      switch (event.type) {
        case APP_EVENTS.TOGGLE_CLIENT_ADMIN_OVERFLOW:
          setTimeout(() => {
            this.overflow = event.data;
          }, 0);
          break;
        case APP_EVENTS.WARNING_POP_UP:
          this.isLoggedOut = event.data;
          break;
      }
    })
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
