import { Injectable } from '@angular/core';
import { RestService } from 'src/app/core/services';
import { Router } from '@angular/router';
import { LoggerService } from 'src/app/core/services';
import { API_KEYPOINT } from 'src/app/core/constants';
import { BehaviorSubject } from 'rxjs';

export interface IqueryParams {
  limit: Number;
  pageno: Number;
  userId: Number;
  parentId: Number;
  isTaged?: Number;
  isFolderSearch?: boolean;
  isCopyMoveModal?: boolean;
}

export interface IFolderDetails {
  firstName: String;
  lastName: String;
  userId: Number;
  folderCount: string;
  assignedCount: String;
  parentId: Number;
  assetCounts: string;
  untaggedAssetsCount: String;
  roleId: Number;
  folderName: String;
  folderId: Number;
  isTaged: Number;
  createdAt: String;
  createByFirstname: String;
  createByLastname: String;
  accessType: Number;
  //frontEnd side variables
  isCollapse: Boolean;
  isFolderRightClick: Boolean;
  children: Array<IFolderDetails>;
}

@Injectable()
export class FolderMgmtUtill {
  private leftSideElementList: Array<IFolderDetails> = [];
  private leftSideElementList2: Array<IFolderDetails> = [];
  private highlightedFolder: IFolderDetails;
  private trackRecord: Map<Number, IFolderDetails> = new Map<
    Number,
    IFolderDetails
  >();

  constructor(
    private router: Router,
    private logger: LoggerService,
    private restService: RestService
  ) {}

  public highlightFolder(item: any) {
    this.removeFolderHighlight();
    this.highlightedFolder = item;
    let ele = document.getElementById(`item-${this.highlightedFolder.folderId}`);
    ele?.classList.add("folderHighlight");
  }

  public removeFolderHighlight() {
    if(this.highlightedFolder) {
      let ele = document.getElementById(`item-${this.highlightedFolder.folderId}`);
      ele?.classList.remove("folderHighlight");
    }
  }

  public getFolderList(): Array<IFolderDetails> {
    return this.leftSideElementList;
  }

  public getFolderList2(): Array<IFolderDetails> {
    return this.leftSideElementList2;
  }

  public setFolderList(leftSideElementList: Array<IFolderDetails>) {
    this.leftSideElementList = leftSideElementList;
  }

  public setTrackRecord(folder: IFolderDetails) {
    this.trackRecord.set(folder.folderId, folder);
  }

  public getTrackRecord(parentId: Number): IFolderDetails {
    let folder = this.trackRecord.get(parentId);
    return folder == undefined ? ({} as IFolderDetails) : folder;
  }

  public async openFolder(item: IFolderDetails) {
    //toggle folder isCollapse
    this.leftSideElementList.forEach((folderitem: IFolderDetails) => {
      folderitem.folderId === item.folderId
        ? (folderitem.isCollapse = true)
        : (folderitem.isCollapse = false);
    });
    if (item.folderCount && parseInt(item.folderCount) >= 1) {
      //get folder list
      await this.setFolderElementList({
        isTaged: 0,
        limit: 1000,
        pageno: 1,
        parentId: item.folderId,
        userId: 1,
        isFolderSearch: false,
        isCopyMoveModal: false,
      });
      this.router.navigate([`main/tagging-assets/list/`]);
    } else {
      //navigate to asset list page
      this.router.navigate([`main/tagging-assets/assetList/`]);
    }
  } //openFolder

  private async getFolderListByQueryParam(
    queryParam: IqueryParams
  ): Promise<IFolderDetails[]> {
    let folderList: Array<IFolderDetails> = [];
    queryParam.limit = 1000;
    try {
      await new Promise((resolve, reject) =>
        setTimeout(() => {
          this.restService
            .post(API_KEYPOINT.folder.folderList, queryParam)
            .subscribe({
              next: (data: any) => {
                if (data.code == 200) {
                  folderList = data.result;
                }
                resolve(folderList);
              },
              error: (error: any) => {
                reject(error);
              },
            });
        })
      );
    } catch (error: any) {
      // this.messageService.add({ severity: 'warn', summary: 'Warning!', detail: error.message });
      this.logger.log('folderMgmtUtill says:: ', error);
    }
    return folderList;
  } //getFolderListByQueryParam

  public async setFolderElementList(queryParam: IqueryParams) {
    await this.getFolderListByQueryParam(queryParam).then((data) => {
      this.leftSideElementList = data;
    });
  } //setFolderMgmtLeftSideList

  public async setFolderElementList2(queryParam: any) {
    await this.getFolderListByQueryParam(queryParam).then((data) => {
      this.leftSideElementList2 = data;
    });
  } //setFolderMgmtLeftSideList

  //data structure to store info of opened folder
  //behavior subject to share the sourcefolder information
  public infoOfOpenedFolder$ = new BehaviorSubject<any>([]);
  infoOfOpenedFolderObservable$ = this.infoOfOpenedFolder$.asObservable();

  arrFolderInfoDS = new Array();

  //this method is being used for adding element to the stack data structure based on some conditions
  addItemToOpenedFolderDataStruct(item: any) {
    let newSet = new Set();

    //add items children to the set
    if (item.children) {
      item.children.map((itemChild: any) => {
        newSet.add(itemChild.folderId);
      });
    }
    let tempObject = {
      folderItem: item,
      setDS: newSet,
    };
    //now add tempObject to the current array that is our stack
    let idx = this.arrFolderInfoDS.length - 1;
    let flag = true;
    while (
      idx > -1 &&
      this.arrFolderInfoDS[idx].setDS.has(item.folderId) === false
    ) {
      if (this.arrFolderInfoDS[idx].folderItem.folderId === item.folderId) {
        this.arrFolderInfoDS.pop();
        flag = false;
        break;
      } else {
        this.arrFolderInfoDS.pop();
        idx = this.arrFolderInfoDS.length - 1;
      }
    }
    if (flag === true) {
      this.arrFolderInfoDS.push(tempObject);
    }

    this.infoOfOpenedFolder$.next(this.arrFolderInfoDS);
  }

  //behavior subject to share the sourcefolder information
  private shareSourceFolderInfo$ = new BehaviorSubject<any>({});
  shareSourceFolderInfoObservar$ = this.shareSourceFolderInfo$.asObservable();

  setNewSourceFolderInfo(val: any) {
    this.shareSourceFolderInfo$.next(val);
  }

  //behaviour subject to open queue list on folder download
  private openQueueList$ = new BehaviorSubject<any>({});
  openQueueListModal$ = this.openQueueList$.asObservable();

  setOpenQueueTrigger(val: any) {
    this.openQueueList$.next(val);
  }

  //behavior subject to call asset-master method
  private makeCallToMethod$ = new BehaviorSubject<any>(false);
  makeCallToTheMethodOfAssetMaster$ = this.makeCallToMethod$.asObservable();

  setToCallMethodTrigger(val: any) {
    this.makeCallToMethod$.next(val);
  }

  //behavior subject to share the sourcefolder information
  private transferApiData$ = new BehaviorSubject<any>({});
  transferApiDataObservar$ = this.shareSourceFolderInfo$.asObservable();

  setTransferApiData(val: any) {
    this.shareSourceFolderInfo$.next(val);
  }
} //FolderMgmtUtill
