<!-- && assetDetails.length > 0 -->
<div class="col asset-master-top-bar-wrapper">
  <div class="d-flex justify-content-end d-grid gap-3">
    <div class="form-group d-flex align-items-center" *ngIf="assetDetails.length > 0">
      <input class="form-check-input small cursorpointer" type="checkbox" id="selectAll" (click)='selectAll($event)'
        [(ngModel)]="selectAllCheck">
      <label class="nh-SelectAll-label" for="selectAll">Select All</label>
    </div>
    <app-btn-dropdown aria-labelledby="dropdownMenuLink" title="Page Size" [options]="pageSizeOptions"
      (filterSelected)="changeSize($event)" [customBtnTemplate]="pageSizeFilterBtnTemplate"
      *ngIf="assetDetails.length > 0" [base]="selectedFilter.pageSize">
    </app-btn-dropdown>
    <ng-template #pageSizeFilterBtnTemplate let-data="data">
      <a class="filter-btn" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
        <span>Page Size</span>
        <img src="./assets/images/icons/down-arrow-black.svg" class="ms-1">
      </a>
    </ng-template>
    <app-btn-dropdown aria-labelledby="dropdownMenuButton1" title="Tag status" [options]="tagStatusOptions"
      (filterSelected)="selectAssetStatusForSorting($event)" [multiselect]="true" [base]="selectedFilter.assetStatus"
      [customBtnTemplate]="tagStatusFilterBtnTemplate">
    </app-btn-dropdown>
    <ng-template #tagStatusFilterBtnTemplate let-data="data">
      <a class="filter-btn position-relativerole" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
        <span>Tag status</span>
        <img src="./assets/images/icons/down-arrow-black.svg" class="ms-1">
        <img *ngIf="selectedFilter.assetStatus && selectedFilter.assetStatus.length>0" class="active"
          src="./assets/images/icons/ic_filter_checked.svg" width="16" alt="checked filter">
      </a>
    </ng-template>
  </div>
</div>
<div *ngIf="totalRecords !== 0" class="zoom-range-slider z-1 p-1">
  <app-grid-zoom-slider [value]="rangeSliderValue" (valueChange)="onChangeRangeSlider($event)">
  </app-grid-zoom-slider>
</div>
<div [ngClass]="(rangeSliderValue == 3 ? 'assets-card-lg' : '') || (rangeSliderValue == 1 ? 'assets-card-sm' : '')"
  class="row gx-4 row-cols-1 row-cols-md-2 row-cols-xl-3 row-cols-xxl-3 card-sec mx-0 image-grid">
  <ng-container *ngFor="let asset of assetDetails ; let i=index">
    <div (mouseleave)="handleMouseLeave()" class="col mt-4 cardmenu" oncontextmenu="return false;">
      <div class="h-100" (contextmenu)="getRightClickPermission($event,asset)"
        (click)="searchResult(asset.id, {assetPath:asset.assetPath,rangeSlider:rangeSliderValue})">
        <div class="card">
          <div class="cardimage">
            <a class="card-hidden-cta"></a>
            <div class="card-image-wrap d-flex flex-column justify-content-center align-items-center">
              <ng-container [ngSwitch]="asset.assetType">
                <ng-container *ngSwitchCase="0">
                  <img [src]="asset.thumbnailPresignedUrl" class="media assetprofilebg" alt="image">
                  <img [src]="asset.thumbnailPresignedUrl" class="media mw-100 mh-100 w-auto h-auto m-auto end-0 bottom-0"
                    alt="image">
                </ng-container>
                <ng-container *ngSwitchCase="1">
                  <img [src]="asset.thumbnailPresignedUrl" class="media mw-100 mh-100 w-auto h-auto m-auto end-0 bottom-0"
                    alt="image">
                  <img *ngIf="asset.thumbnailAssetName" src="/assets/images/pages/asset-card/ic_play_circle.svg"
                    alt="Play" class="m-auto z-1" width="38px" height="38px">
                  <img *ngIf="!asset.thumbnailAssetName" src="assets/images/icons/Img.svg" class="media mw-100 mh-100"
                    alt="image">
                </ng-container>
                <ng-container *ngSwitchCase="2">
                  <img [src]="'assets/images/icons/document/' + commonUtils.getFileExtIcon(asset) + '_icon.svg'" 
                    class="media w-100 h-100" alt="image" >
                </ng-container>
                <ng-container *ngSwitchCase="3">
                  <img src="assets/images/icons/audio_icon.svg" alt="Audio" class="media">
                </ng-container>
                <ng-container *ngSwitchCase="4">
                  <img src="assets/images/icons/ic_others_icon.svg"
                    class="media mw-100 mh-100 w-100 h-100 bottom-0 end-0 m-auto" alt="image">
                </ng-container>
              </ng-container>
            </div>
            <span class="card-top-overlay"></span>
            <span class="card-top-overlay"></span>
            <div class="card-img-overlay d-flex flex-column z-1 card-overlay-cta">
              <div class="m-0 d-flex justify-content-end">
                <div>
                  <div class="badge card-tag completed rounded-0" *ngIf="asset.assetTag==1">Completed</div>
                  <div class="badge card-tag pending rounded-0" *ngIf="asset.assetTag==0">Pending</div>
                </div>
                <div class="d-flex">
                  <span class="ms-auto">
                    <div class="form-check">
                      <input class="form-check-input form-check-input1" type="checkbox"
                        (click)="[$event.stopPropagation()]" value="{{asset}}" binary="true" id="{{asset.id}}"
                        [(ngModel)]="asset.isSelected" (change)="assetSelected(asset)">
                    </div>
                  </span>
                </div>
              </div>
              <div class="mt-auto d-flex justify-content-between card-icon position-relative">
                <div class="d-flex gap-2 flex-wrap position-relative align-items-end">
                  <div
                    [ngClass]="{'openSharePopUp':(openSharePopUpAsset==asset.assetName), 'closeSharePopUp':(openSharePopUpAsset!=asset.assetName)}"
                    (click)="[$event.stopPropagation(), openSharePopUpAsset='']">
                    <share-buttons [theme]="'circles-dark'" [include]="['whatsapp','email']" [show]="3"
                      [url]="preSignedUrl[asset.id]"
                      [title]="'Share'" (click)="saveShareLink(asset)"></share-buttons>
                    <share-button button="functionButton" (click)="[copyLinkToClipboard(asset), saveShareLink(asset)]"
                      class="copy-link-wrapper" title="Copy Link"></share-button>
                    <span class="gdrive" (click)="[ShareAssetsOnGdrive(asset), saveShareLink(asset)]">
                      <img src="/assets/images/icons/ic_google_drive.svg" style="height: 24px;" alt="google_drive">
                    </span>
                  </div>
                  <div>
                    <div>
                      <button type="button" title="Share" (click)="[openSharePopUp(asset.assetName,$event), checkforPreSignedUrl(asset.id)]"
                        *ngIf="selectedAssets.length<2" class="btn btn-icon-overlay  justify-content-center">
                        <i class="nm_icon">
                          <img src="/assets/images/pages/asset-card/ic_share.svg">
                        </i>
                      </button>
                      <div class="dropdown-menu card_share_tooltip w-auto">
                        <div class="d-flex justify-content-between w-auto">
                          <a class="dropdown-item w-auto d-flex justify-content-center align-items-center" href="#">
                            <i class="d-flex justify-content-center align-items-center">
                              <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M21.252 3.63507C18.9178 1.2889 15.8054 0 12.4911 0C5.66642 0 0.106914 5.55952 0.100974 12.3842C0.100974 14.57 0.671179 16.6964 1.75219 18.5792L0 25L6.56924 23.2775C8.38083 24.2635 10.4181 24.7862 12.4911 24.7862H12.497C19.3217 24.7862 24.8812 19.2267 24.8871 12.3961C24.8812 9.08767 23.5923 5.97529 21.252 3.63507ZM12.4911 22.6895C10.6379 22.6895 8.82631 22.1905 7.24636 21.2521L6.87217 21.0264L2.97576 22.048L4.0152 18.2466L3.77167 17.8546C2.73817 16.2153 2.19767 14.3205 2.19767 12.3782C2.19767 6.71181 6.81871 2.09076 12.497 2.09076C15.2471 2.09076 17.8308 3.16584 19.779 5.1081C21.7213 7.05631 22.7904 9.64006 22.7904 12.3901C22.7845 18.0744 18.1634 22.6895 12.4911 22.6895ZM18.1397 14.9798C17.8308 14.8254 16.3103 14.077 16.0252 13.9701C15.7401 13.8691 15.5322 13.8156 15.3302 14.1245C15.1223 14.4334 14.5284 15.1342 14.3502 15.3362C14.172 15.5441 13.9879 15.5678 13.679 15.4134C13.3701 15.259 12.3723 14.9323 11.1903 13.875C10.2696 13.0554 9.65192 12.0397 9.46779 11.7308C9.2896 11.422 9.44997 11.2556 9.6044 11.1012C9.74101 10.9646 9.91326 10.7389 10.0677 10.5607C10.2221 10.3825 10.2756 10.2518 10.3766 10.044C10.4775 9.83607 10.43 9.65788 10.3528 9.50345C10.2756 9.34902 9.65786 7.82252 9.39651 7.2048C9.14705 6.59896 8.89164 6.68211 8.70158 6.67617C8.52339 6.66429 8.3155 6.66429 8.10761 6.66429C7.89972 6.66429 7.5671 6.74151 7.282 7.05037C6.9969 7.35923 6.20099 8.10763 6.20099 9.63412C6.20099 11.1606 7.3117 12.6277 7.46613 12.8356C7.62056 13.0435 9.64598 16.1677 12.7524 17.5101C13.4889 17.8308 14.0651 18.0209 14.5165 18.1635C15.2589 18.401 15.9301 18.3654 16.4647 18.2882C17.0587 18.1991 18.2941 17.5398 18.5554 16.8152C18.8108 16.0905 18.8108 15.4728 18.7336 15.3421C18.6564 15.2115 18.4485 15.1342 18.1397 14.9798Z"
                                  fill="white" />
                              </svg>
                            </i>
                          </a>
                          <a class="dropdown-item w-auto d-flex justify-content-center align-items-center" href="#">
                            <i class="d-flex justify-content-center align-items-center">
                              <img src="/assets/images/icons/ic_google_drive.svg" style="height: 24px;"
                                alt="google_drive">
                            </i>
                          </a>
                          <a class="dropdown-item w-auto d-flex justify-content-center align-items-center" href="#">
                            <i class="d-flex justify-content-center align-items-center">
                              <svg width="26" height="19" viewBox="0 0 26 19" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M1.05843 2.55538C1.37428 1.16381 2.61881 0.125 4.10596 0.125H22.856C24.3431 0.125 25.5876 1.16381 25.9035 2.55538L13.481 10.1469L1.05843 2.55538ZM0.980957 4.33919V15.4384L10.0484 9.88044L0.980957 4.33919ZM11.5456 10.7954L1.28076 17.0873C1.7819 18.1442 2.85858 18.875 4.10596 18.875H22.856C24.1033 18.875 25.18 18.1442 25.6812 17.0873L15.4163 10.7954L13.481 11.9781L11.5456 10.7954ZM16.9135 9.88044L25.981 15.4384V4.33919L16.9135 9.88044Z"
                                  fill="white" />
                              </svg>
                            </i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button type="button" class="btn btn-icon-overlay  justify-content-center" title="Download"
                    (click)="redirectToNewTab(asset , $event)" *ngIf="selectedAssets.length<2">
                    <i class=" nm_icon">
                      <img src="/assets/images/pages/asset-card/ic_download.svg">
                    </i>
                  </button>
                </div>
                <div class="d-flex gap-2 flex-wrap position-relative">
                  <button
                    *ngIf="selectedAssets.length<2 && (asset?.assetType==0 || asset?.assetType==1 || asset?.assetType==3)"
                    title="Preview" type="button" class="btn btn-icon-overlay justify-content-center ms-2"
                    (click)="openSliderModal(asset, $event)">
                    <i class="nm_icon">
                      <img alt="Preview" src="/assets/images/pages/asset-card/view.svg" />
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="card-event pb-1">
              <small class="text-muted event-details d-flex justify-content-between gap-1">
                <span class="border-0 d-flex m-0 p-0 flex-grow-1 justify-content-start align-items-center">
                  <img src="/assets/images/pages/asset-card/icon_calendar.svg">
                  <p class="m-0">{{ asset.eventTime ? (asset.eventTime | date) : (asset.createdAt | date) }}</p>
                </span>
                <span *ngIf="asset.eventLocation" class="m-0 p-0"></span>
                <span class="border-0 d-flex m-0 p-0 flex-grow-1 justify-content-center align-items-center"
                  *ngIf="asset.eventLocation">
                  <img src="/assets/images/pages/asset-card/icon_location.svg">
                  <p class="m-0">{{asset.eventLocation}}</p>
                </span>
                <span class="m-0 p-0"></span>
                <span class="border-0 d-flex m-0 p-0 flex-grow-1 justify-content-end align-items-center">
                  <img src="/assets/images/pages/asset-card/icon_file_size.svg">
                  <p class="m-0">{{asset.assetSize | formatBytes:2}}</p>
                </span>
              </small>
            </div>
            <div class="card-title mb-0 pb-2 pt-2 position-relative custom-tooltip-hover" *ngIf="asset.manualTags">
              <div class="title-text">
                {{asset.title || asset.assetName}}
              </div>
              <span class="hover-text-details">{{asset.title || asset.assetName}}</span>
            </div>
            <div>
              <ul class="list-group list-group-flush" style="border: none;">
                <li class="list-group-item px-0 py-1 d-flex flex-grow-1 justify-content-start align-items-center">
                  <img src="/assets/images/pages/asset-card/ai-icon.svg" class="left-curve me-2">
                  <span class="dot"></span>
                  <span class="tag-text card-text ms-2">
                    {{(tagArray[i].longTagArrAi.length < 1 && asset.isAutoTaged===0 ) ? 'AI tagging pending.' :
                    (tagArray[i].longTagArrAi.length < 1 && asset.isAutoTaged===2 ) ? 'No AI tags available.' :
                    (asset.isAutoTaged===1 && tagArray[i].longTagArrAi.length>=1 ) ?
                     ( tagArray[i].longTagArrAi.toString().replaceAll(',',', ') | titlecase) :'No AI tags available.' }}

                  </span>
                  <span class="hover-text-details">
                    {{(tagArray[i].longTagArrAi.length < 1 && asset.isAutoTaged===0 ) ? 'AI tagging pending.' :
                    (tagArray[i].longTagArrAi.length < 1 && asset.isAutoTaged===2 ) ? 'No AI tags available' :
                    (asset.isAutoTaged===1 && tagArray[i].longTagArrAi.length>=1 ) ?
                    ( tagArray[i].longTagArrAi.toString().replaceAll(',',', ') | titlecase) :'No AI tags available.' }}
                  </span>
                </li>
                <li class="list-group-item px-0 py-1 d-flex flex-grow-1 justify-content-start align-items-center">
                  <img src="assets\images\icons\tag-icon.svg" class="me-2">
                  <span class="dot"></span>
                  <span class="tag-text card-text ms-2">
                    {{(tagArray[i].longTagArrManual.length < 1 ) ? ' Manual tagging pending.' :
                      tagArray[i].longTagArrManual.toString().replaceAll(',',', ')}}
                  </span>
                  <span class="hover-text-details">
                    {{(tagArray[i].longTagArrManual.length < 1) ? ' Manual tagging pending.' :
                      tagArray[i].longTagArrManual.toString().replaceAll(',',', ')}}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="card-footer cursorpointer">
            <div class="transcript-cntr gap-2">
              <div class="d-flex justify-content-start align-items-center w-50">
                <div class="d-flex" *ngIf="asset.assetType!=2 && asset.assetType!=4 && asset.assetType!=3">
                  <span class="ic-dimension me-3">
                    <img src="/assets/images/pages/asset-card/ic_resolution.svg" alt="ic_resolution"
                      style="height: 16px;">
                  </span>
                  <p class="mb-0 mt-1">{{asset.height}} x {{asset.width}}</p>
                </div>
              </div>
              <div *ngIf="asset.assetType!=2 && asset.assetType!=4 && asset.assetType!=3"
                style="background: #DDE5F6;width: 1px;"></div>
              <div class="d-flex justify-content-end align-items-center w-50">
                <span class="ic-user me-3">
                  <img src="/assets/images/pages/asset-card/ic_createdBy.svg" alt="created_by_icon"
                    style="height: 16px;">
                </span>
                <p class="font-medium mb-0 mt-1">{{asset.createdBy}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="d-flex justify-content-center mt-3 pb-3 w-100">
    <button [disabled]="totalRecords<=assetDetails.length" type="button" (click)="seeMore()"
      *ngIf="totalRecords !== 0 && totalRecords>assetDetails.length" class="btn btn-primary">See More</button>
  </div>
</div>
<nav #contextMenuW id="context-menu1" [ngClass]="contextmenu ? ' border-0' : 'border-0' "
  class="context-menu d-block overflow-hidden asset-context-menu"
  [ngStyle]="{'left.px': contextmenuX, 'top.px' : contextmenuY}">
  <app-context-menu *ngIf="contextmenu" [menuitems]="newAssetContextMenuList"
    (menuItemSelected)="handleMenuSelection($event)" [selectedAssets]="selectedAssets">
  </app-context-menu>
</nav>

<div class="placeholderimg custom-placeholder-image" *ngIf="totalRecords === 0">
  <img src="/assets/images/icons/no-assets-placeholder-img.svg" alt="no assets placeholder">
  <br>
  <span>No assets present!</span>
</div>

<!-- Preview for Image assets name-->
<p-dialog [modal]="true" [visible]="isTrimImage" [breakpoints]="{'1030px': '850px', '991px': '90vw'}"
  [resizable]="false" [style]="{width: '600px'}" [showHeader]="false">
  <div class="d-flex justify-content-between flex-row pt-4 pb-2">
    <h4 class="font-bold">Preview</h4>
    <button type=" button" (click)="closeTrimImage(trimImageFrom)"
      class="btn btn-close justify-content-center save-cropped-img me-2"></button>
  </div>
  <div class="img-wrapper d-flex align-items-center justify-content-center">
    <img [src]="croppedsrcUrl" alt="Preview" class="mw-100">
  </div>

  <form [formGroup]="trimImageFrom" (ngSubmit)="submitFormDataImage(trimImageFrom)">
    <div class="row align-items-start mt-2">
      <div class="col-sm">
        <div class="form-group mb-0">
          <label for="imageName" class="form-label font-bold">Name
            <span class="invalid-feedback font-medium d-inline-block w-auto">*</span>
          </label>
          <input type="text" class="form-control" formControlName="imageName" name="imageName" required
            placeholder="Enter Name" />
          <div class="invalid-feedback d-block font-medium mb-2"
            *ngIf="trimImageFrom.controls['imageName'].hasError('required') && trimImageFrom.controls['imageName'].dirty">
            Name is required
          </div>
          <div class="invalid-feedback d-block font-medium mb-2"
            *ngIf="trimImageFrom.get('imageName').hasError('minlength')">
            Name must be at least 2 characters long.
          </div>
          <div class="invalid-feedback d-block font-medium mb-2"
            *ngIf="trimImageFrom.get('imageName').hasError('maxlength')">
            Name cannot exceed 100 characters.
          </div>
          <div class="invalid-feedback d-block font-medium mb-2"
            *ngIf="trimImageFrom.get('imageName').hasError('pattern')">
            Only - and _ are allowed as special characters and leading or trailing spaces are not allowed.
          </div>
        </div>
      </div>
      <div class="col-sm">
        <div class="form-group mb-0">
          <label for="VideoName" class="form-label font-bold">Type
            <span class="invalid-feedback font-medium d-inline-block w-auto"></span>
          </label>
          <input type="text" class="form-control" placeholder="{{imageExt ? imageExt : ''}}" disabled />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="d-grid d-flex gap-3 pt-3 flex-wrap justify-content-center">
          <button type="button" (click)="onCancel()" class="btn btn-secondary justify-content-center btn-min-width"
            [disabled]="!trimImageFrom.dirty" style="color: #116ACC; border: 1px solid #116ACC;">
            Reset
          </button>
          <button type="submit" class="btn d-btn-primary btn-primary justify-content-center btn-min-width border-0"
            [disabled]="
              trimImageFrom.invalid ||
              ((trimImageFrom.controls['imageName'].value?.length >= 1 &&
                trimImageFrom.controls['imageName'].value?.length < 2) ||
                trimImageFrom.controls['imageName'].value?.length > 100) &&
              (trimImageFrom.controls['imageName'].dirty || trimImageFrom.controls['imageName'].touched || trimImageFrom.submitted)">
            Save
          </button>
        </div>
      </div>
    </div>
  </form>


</p-dialog>

<!-- dialog box for delete confirmation -->
<app-confirmationPopUp *ngIf="isDeleteModalShow" (onCancel)="closedeleteModal()"
  [show]="isDeleteModalShow" (onConfirm)="onItemDeleted()"></app-confirmationPopUp>


<div class="container-fluid" *ngFor="let asset of selectedAssets">
  <app-video-trim #trimvideo [isTrimVideo]="isTrimVideo" (showPreview)="showPreview($event)"
    assetType="{{asset.assetType}}" source="{{preSignedUrl[asset?.id]}}"
    assetName="{{asset?.assetName}}" isSaved="isSaved" assetPath="{{asset?.assetPath}}" referenceAssetId="{{asset?.id}}"
    *ngIf="asset.assetType==1 || asset.assetType==3" folderId="{{childFolderId}}" class="w-100"></app-video-trim>

</div>


<!-- Asset Preview -->
<ng-container *ngIf="showAssetPreview">
  <app-asset-preview [assetsList]="assetDetails" [currentItem]="slideritemdata" [preSignedUrl]="preSignedUrl"
    (onActionPerformed)="onAssetPreviewChange($event)"></app-asset-preview>
</ng-container>


<!-- Modal Copy  -->
<p-dialog class="copy-move-popup movetoasset" [draggable]="false" [header]="assetsHeader" [modal]="true"
  [(visible)]="displayDialogBox" [breakpoints]="{ '500px': '90vw'}" [style]="{width: '50%'}" [showHeader]="true"
  [resizable]="false" (onShow)="onCopyMoveDialogShow()">
    <div class="p-0">
      <div class="tab-content sidepanel position-relative" id="v-pills-tabContent">
    
        <div class="tab-pane fade" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
    
          <div class=" d-flex justify-content-start align-items-center" *ngIf="breadCrumbArrayOfFolderItem.length >= 1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb arr-right breadcrumb_ath">
                <li class="breadcrumb-item breadcrumb-secitem"
                  *ngFor="let item of breadCrumbArrayOfFolderItem; let i = index">
                  <a class="breadcrumb_foldername mt-1" (click)="openFolderThroughBreadCrumb(item.folderItem)">
                    {{item.folderItem.folderName}}
                  </a>
                </li>
              </ol>
            </nav>
          </div>
          <div class="tree-view-nav">
            <ul id="myUL" class="tree-ul">
              <li>
                <div>
                  <app-dynamic-nested-list [items]="firstItem" [parentId]="firstItem.parentId"
                    (refresh)="openFolder($event)" [isOpenedTrhoughModal]=true>
                  </app-dynamic-nested-list>
                </div>
              </li>
            </ul>
          </div>
    
        </div>
        <div class="d-flex upload-asset-modal-btn d-gird gap-3 justify-content-md-end z-1">
          <button type="button" class="btn btn-secondary btn-min-width" (click)="emptyFileList()"
            style="color: #116ACC; border: 1px solid #116ACC;">Cancel</button>
          <button type="button" class="btn d-btn-primary btn-primary btn-min-width border-0" (click)="checkSubmitCopyData()"
            [disabled]="isDestinationFolderDisabled">{{assetsFooter}}</button>
        </div>
      </div>
    </div>
</p-dialog>

<!----------------Modal to tak assets in bulk (BULK TAG)------------------->
<app-bulk-tag *ngIf="isBulkAssetTag==true" [selectedAssets]="selectedAssets" [objectForSourceTrack]="objectForSourceTrack" [assetTag]="assetTag" (closeBulktag)="closeBulkTagModal($event)"></app-bulk-tag>