import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, from, Observable, Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { environment } from '../../environment/environment';
import { FormatS3UrlPipe } from 'src/app/shared/pipes/format-s3-url.pipe';
import { Clipboard } from '@angular/cdk/clipboard';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private subject$ = new Subject<any>();
  public api_url = environment.apiUrl;
  public key = environment.secretKey;
  public s3BaseUrl =
    environment.infra === 'aws'
      ? environment.s3BaseUrl
      : environment.GCPBaseUrl;
  public videoLength$: BehaviorSubject<any> = new BehaviorSubject(null);
  public folderPathUrl: any;
  public folderIds: any;
  public folderRoute: any;
  public s3Url = environment.s3Url;
  public previousHitURL: any = ''; //
  private formatS3UrlPipe = new FormatS3UrlPipe();

  user: any = '';

  constructor(private clipboard: Clipboard, private router: Router) {}

  //common function for copyLinkToClipboard
  copyLinkToClipboard(assetPresignedUrl: any): void {
    this.clipboard.copy(assetPresignedUrl);
  }
  // function to redirected to search result page
  searchResult(id: any, queryParams: any, target?: any, reload?: boolean) {
    queryParams.assetPath = queryParams.assetPath.split('/').slice(1).join('/');
    const urlTree = this.router.createUrlTree(
      ['/main/' + id + '/search-result'],
      {
        queryParams: { ...queryParams },
      }
    );
    if (reload) {
      window.open(urlTree.toString(), target ? target : '_self');
    } else {
      this.router.navigateByUrl(urlTree.toString(), {
        skipLocationChange: false,
      });
    }
  }

  parseSubtitle(srtContent: string): string[] {
    return srtContent.split('\n\n\n');
  }

  getSubtitle(url: string): Observable<string> {
    const fetchPromise = fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/plain',
      },
    }).then((response) => {
      if (!response.ok) {
        throw new Error('Error while downloading');
      }
      return response.text();
    });
    return from(fetchPromise);
  }

  getImage(url: string): Observable<string> {
    const fetchPromise = fetch(url, {
      method: 'GET',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error while downloading');
        }
        return response.blob(); // Get the response as a Blob
      })
      .then((blob) => {
        return new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string); // Convert Blob to Base64 string
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      });

    return from(fetchPromise);
  }
}
