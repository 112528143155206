<header class="header-panel d-flex align-items-center">
  <nav class="navbar navbar-expand-lg w-100 navbar-dark">
    <div class="container-fluid">
      <!-- <a class="navbar-brand" href="#">Navbar</a> -->
      <div class="align-items-center d-flex header-nav-wrapper">
        <div class="header_logo">
          <a [routerLink]="appRouteUrl.home" class="navbar-brand text-white text-decoration-none">
            <span class="logo_img">
              <img src="assets/images/pages/header/jio-logo.svg" alt="jio-logo-image">
              <div class="logo_text">DAM</div>
            </span>
          </a>
        </div>
        <div>
          <button class="navbar-toggler" type="button" data-bs-auto-close="inside outside" data-bs-toggle="collapse"
            data-bs-target="#primarynavigation" aria-controls="primarynavigation" aria-expanded="false"
            aria-label="Toggle navigation" (click)="isMenuCollapsed = !isMenuCollapsed">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse navbar-collapse_custom" id="primarynavigation"
            [ngbCollapse]="isMenuCollapsed">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0 navbar-space">
              <ng-container *ngFor="let item of routerConfig; let i = index">
                <li class="nav-item dropdown nav-arrow" *ngIf="item.isShowMenu" routerLinkActive="active"
                  (click)="toggleSidenav(item)">
                  <div>
                    <div *ngIf="item.url === ''; else elseblock">
                      <a class="nav-link text-white dropdown-toggle" title="{{item.label}}" data-bs-toggle="dropdown"
                        aria-expanded="false" role="button">
                        {{item.label}}&nbsp;&nbsp;<span></span>
                      </a>
                      <ul *ngIf="item.isChildOpen" class="dropdown-menu dropdown-menu-end">
                        <ng-container *ngFor="let chlidItem of item.childs;">
                          <li *ngIf="chlidItem.isShowMenu" class="pointer">
                            <div *ngIf="chlidItem.url">
                              <a aria-hidden="true" routerLink="/{{chlidItem.url}}" (click)="redirectChild(chlidItem)"
                                class="dropdown-item" routerLinkActive="active"
                                (click)="isMenuCollapsed = true">{{chlidItem.label}}</a>
                            </div>
                          </li>
                        </ng-container>
                      </ul>
                    </div>
                    <ng-template #elseblock>
                      <a class="nav-link text-white" routerLink="/{{item.url}}" title="{{item.label}}"
                        routerLinkActive="active" (click)="isMenuCollapsed = true">
                        {{item.label}}
                      </a>

                    </ng-template>
                  </div>
                </li>
              </ng-container>
              <li class="nav-item dropdown nav-arrow d-lg-none">
                <a title="Clip List" class="nav-link text-white" routerLinkActive="active"
                  (click)="isMenuCollapsed = true"> Clip List </a>
              </li>
              <li class="nav-item dropdown nav-arrow d-lg-none">
                <a title="Collections" class="nav-link text-white" routerLinkActive="active"
                  (click)="isMenuCollapsed = true"> Collections </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="queueListToggleButton" class="d-flex align-items-center">
        <div class="dropdown header-search-wrapper ms-lg-2 d-none d-lg-block"
          *ngIf="imageRevRequest.totalCount && permissions['imageReverseSearch']">
          <button class="btn-search-dropdown p-0 btn-notification" type="button" title="Search by Images"
            (click)="maximizeGlobalUploadModal()">
            <span class="search-main" title="Upload Status">
              <i>
                <img src="assets/images/pages/header/ic_cloud.svg" alt="cloud_img_reverse_search" class="wh-icon">
              </i>
            </span>
            <ng-container *ngIf="imageRevRequest.totalCount === imageRevRequest.doneCount; else progressTemp">
              <img class="btn-notification-status" src="/assets/images/pages/header/succes.svg" alt="success">
            </ng-container>
            <ng-template #progressTemp>
              <svg class="btn-notification-progress" viewBox="0 0 100 100">
                <circle class="progress-ring" [attr.stroke-dasharray]="imageRevRequest.circumference"
                  [attr.stroke-dashoffset]="imageRevRequest.donePercentage"
                  [attr.transform]="'rotate(' + -90 + ' 50 50)' " cx="50" cy="50" r="40"></circle>
              </svg>
            </ng-template>
          </button>
        </div>
        <div class="dropdown notification-ui header-search-wrapper ms-lg-2 d-none d-lg-block">
          <span
            class="search-main clip-list-icon d-flex justify-content-center align-items-center dropdown-toggle notification-ui_icon"
            role="button" id="navbarDropdown" data-bs-toggle="dropdown" aria-bs-haspopup="true" aria-bs-expanded="false"
            title="Queue List" (click)="getQueueList()">
            <i class="d-flex"><img class="wh-icon" src="assets/images/pages/header/prime_list.svg" alt="prime list image"></i>
          </span>
          <div class="dropdown-menu notification-ui_dd dropdown-menu-end" aria-labelledby="navbarDropdown"
            #DropdownMenu>
            <div class="notification-ui_dd-content">
              <div (click)="$event.stopPropagation()" class="notification-list notification-list--unread"
                *ngIf="!(queueList.length)">
                <div class="d-inline-flex justify-content-start">
                  <div class="notification-list_detail">
                    <p>Queue List Empty</p>
                    <p>No Assets in queue list</p>
                  </div>
                </div>
              </div>
              <ng-container *ngFor="let item of queueList">
                <div class="notification-list notification-list--unread align-items-center"
                  (click)="$event.stopPropagation()">

                  <div class="d-inline-flex justify-content-start col-8">
                    <div class="notification-list_img">
                      <img src="/assets/images/pages/header/ic_zip.svg" alt="zip_icon">
                    </div>
                    <div class="notification-list_detail text-break">
                      <p>{{item.actionName}}</p>
                      <p *ngIf="item.actionStatus===1">Zipping Files | {{item.actionMessage}}</p>
                      <!-- <p *ngIf="item.actionStatus===1">Downloading ...</p> -->
                      <p *ngIf="item.actionStatus===2">Download Completed</p>
                      <p *ngIf="item.actionStatus===3" style="color: #BA0004;">Failed</p>
                    </div>
                  </div>
                  <div class="notification-list_feature-img d-flex"
                    *ngIf="item.actionStatus===1 || item.actionStatus===0">
                    <div class="download-comp-ic"
                      [style.background]="'conic-gradient(#3aa316 ' + ((item.doneCount / item.totalCount)*3.6)*100 + 'deg, #a3a9a9 0deg)'">
                      <div class="inner-circle"></div>
                    </div>
                    <img src="/assets/images/pages/header/remove-download.svg" (click)="deleteAssetFromQueue(item.id)"
                      alt="downloading">
                  </div>
                  <div class="notification-list_feature-img d-flex" *ngIf="item.actionStatus===2">
                    <img src="/assets/images/icons/btn-select.svg" alt="downloading"
                      class="download-comp-ic mt-2 me-3 position-relative mt-n3">
                    <img src="/assets/images/pages/header/remove-download.svg" (click)="deleteAssetFromQueue(item.id)"
                      alt="downloading">
                  </div>
                  <div class="notification-list_feature-img d-flex" *ngIf="item.actionStatus===3">
                    <img src="assets/images/icons/retry.svg"
                      (click)="retryDownload(item.id, item.assetArr, item.folderPath, item.folderId, item.downloadType)"
                      alt="retry" class="download-comp-ic mt-2 me-3 position-relative mt-n3">
                    <img src="/assets/images/pages/header/remove-download.svg" (click)="deleteAssetFromQueue(item.id)"
                      alt="downloading">
                  </div>
                </div>
              </ng-container>

            </div>

          </div>
        </div>
        <div class="dropdown header-search-wrapper"
          *ngIf="!(_router.url===appRouteUrl.searchDetails || _router.url=== appRouteUrl.home)">
          <button class="btn-search-dropdown dropdown-toggle pe-0" type="button" id="dropdownSearchBar"
            (click)="handleOpenSearchBar()" data-bs-toggle="dropdown" aria-expanded="false">
            <span class="search-main" title="Search">
              <i><img src="/assets/images/pages/header/ic_search_header.svg" alt="search_asset" style="height:15px;"></i>
            </span>
          </button>
          <div class="search-form-main clearfix active-search dropdown-menu" aria-labelledby="dropdownSearchBar">
            <form role="search" class="search-form" autocomplete="off">
              <input type="search" class="search-field" placeholder="Search Asset" name="search" #searchtext>
              <input type="submit" class="search-submit" (click)="getAssetsListSearch(searchtext.value)">
            </form>
          </div>
        </div>
        <div class="dropdown header-search-wrapper ms-lg-2 d-none d-lg-block" *ngIf="permissions['people'] || false">
          <button class="btn-search-dropdown p-0" type="button" id="dropdownSearchBar" (click)="goToPeopleManagement()">
            <span class="search-main" title="People Management">
              <i><img src="assets/images/pages/header/people-management.svg" alt="people-management"
                  class="wh-icon"></i>
            </span>
          </button>
        </div>
        <div class="dropdown header-search-wrapper ms-lg-2 d-none d-lg-block">
          <button class="btn-search-dropdown p-0" type="button" id="dropdownSearchBar" (click)="goToCliplist()"
            *ngIf="permissions['cliplist'] || false">
            <span class="search-main" title="Add to Cliplist">
              <i><img src="assets/images/pages/header/clip-list.svg" alt="clip-list" class="wh-icon"></i>
            </span>
          </button>

        </div>
        <div class="dropdown header-search-wrapper ms-lg-2 d-none d-lg-block" *ngIf="permissions['collection'] || false">
          <button class="btn-search-dropdown p-0" type="button" id="dropdownSearchBar" (click)="goToCollections()">
            <span class="search-main" title="Add to Collection" [ngClass]="{'bg-danger': isAddedToCollection}">
              <i><img src="assets/images/pages/header/addcollections.svg" alt="addcollections image" class="wh-icon"></i>
            </span>
          </button>
        </div>
        <div class="text-end ms-2 d-flex">
          <div class="dropdown d-inline-flex profile_dropdown">
            <button
              class="user_icon btn bg-white dropdown-toggle d-inline-flex align-items-center justify-content-center"
              data-bs-toggle="dropdown" aria-expanded="false" id="dropdownMenuButton">
              {{getUserFirstName + getUserLastName}}
            </button>
            <ul class="dropdown-menu dropdown-menu-end pt-0" aria-labelledby="dropdownMenuButton">
              <li class="border-bottom dropdown-item loggedin-user-details px-3 py-2">
                <h5 class="w-100 mb-0">{{getUserName}}</h5>
                <span>{{isUserRole}}</span>
              </li>
              <li>
                <ng-container *ngIf="getUserRoleId === 1 && userDetails?.isSuperAdmin; else changeClientLabel">
                  <a class="dropdown-item user-client" routerLinkActive="active" [routerLink]="appRouteUrl.clients">
                    Manage Clients
                    <div class="badge">
                      {{userDetails?.clientName}}
                    </div>
                  </a>
                </ng-container>
                <ng-template #changeClientLabel>
                  <a class="dropdown-item user-client current border-bottom" *ngIf="getUserAssignedClients?.length">
                    <span class="user-client-name">{{userDetails?.clientName}}</span>
                    <img class="float-right cursorpointer" *ngIf="getUserAssignedClients?.length >1"
                      routerLinkActive="active" [routerLink]="appRouteUrl.clients" src="/assets/images/pages/header/ic_swap.png"
                      alt="ic swap image">
                  </a>
                </ng-template>
              </li>
              <li><a class="dropdown-item" routerLinkActive="active" [routerLink]="appRouteUrl.profile">Profile</a></li>
              <li *ngIf="userDetails.authId === 3 && !userDetails?.isSuperAdmin">
                <a class="dropdown-item" routerLinkActive="active" [routerLink]="appRouteUrl.changePassword">
                  Change Password
                </a>
              </li>
              <li><a class="dropdown-item cursorpointer" (click)="showConfirmationPopUp()">Logout</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>
<app-confirmationPopUp [show]="confirmationPopUpVisible" (onCancel)="closeConfirmationPopUp($event)"
(onConfirm)="logout()" ></app-confirmationPopUp>
<!-- <p-toast></p-toast> -->