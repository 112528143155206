import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService, PermissionsEngine, RestService } from 'src/app/core/services';
import { Subscription, filter } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { API_KEYPOINT } from 'src/app/core/constants';

@Component({
  selector: 'app-sidenav-admin',
  templateUrl: './sidenav-admin.component.html',
  styleUrls: ['./sidenav-admin.component.scss'],
})
export class SidenavAdminComponent implements OnInit {
  public routerConfig: any = [];
  userDetails: any;
  isUserRole: any;
  isActiveSidebar: any;
  isUserRoleId: any;
  isCollapsible: boolean = true;
  getUserName: any;
  activeItem: any;
  private subscriptions: Subscription[] = [];
  isSidenavOpen: boolean = false;
  @Output() emitSidenavOpen = new EventEmitter<any>();
  constructor(
    private _router: Router,
    private authService: AuthService,
    public permissionsEngine: PermissionsEngine,
    private restService: RestService,
    private localStorageService: LocalStorageService
  ) {
    this.userDetails = this.authService.getAuthData();
    if (this.userDetails && this.userDetails.role) {
      this.isUserRoleId = this.userDetails.roleId.toString();
      // this.isUserRoleId = '4';
      this.isUserRole = this.userDetails.role;
      // this.subscriptions.push(
      //   this.headerService.sideNavData.subscribe((data: any) => {
      //     if (data.moduleSlug === 'management') {
      //       this.routerConfig = data;
      //       const firstChildRoute = this.routerConfig.childs[0];
      //       if (firstChildRoute) {
      //         this._router.navigate([firstChildRoute.url]);
      //       }
      //     }
      //   }));
    }
  }

  ngOnInit() {
    // this.initData();
    this.getLoggedInUserData();
  }
  logout() {
    this.localStorageService.clear();
    location.reload();
    window.location.href = 'auth/login';
    // this._router.navigateByUrl('/auth/login');
  }

  getLoggedInUserData() {
    this.restService
      .get(API_KEYPOINT.user.userDetails + this.userDetails.userId)
      .subscribe((data: any) => {
        this.getUserName = data.result.firstName;
      });
  }

  redirect(item: any) {
    // Loop through management pages to find any child page with permissions
    this.activeItem = this.routerConfig.find((page: any) => {
      return page.childs.some((child: any) => {
        return this.permissionsEngine.hasPermission(
          child.permission.key,
          child.permission.module
        );
      });
    });

    this._router.navigate([item.url]);
  }

  toggleSidebar() {
    this.isCollapsible = !this.isCollapsible;
    this.isSidenavOpen = !this.isSidenavOpen;
    this.emitSidenavOpen.emit(this.isSidenavOpen);
  }

  isShowMenu(arr: String[]): boolean {
    const hasAllElems = arr.every((elem: any) =>
      this.routerConfig.includes(elem)
    );
    return !!hasAllElems;
  }

  redirectChild(item: any) {
    this._router.navigate([item.url]);
    this.isActiveSidebar = item.number;
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
} //SidenavAdminComponent
