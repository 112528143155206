<p-dialog *ngIf="isOpenCollectionModal" [modal]="true" [draggable]="false" [visible]="isOpenCollectionModal" class="onboarduser-form"
  [breakpoints]="{'1030px': '850px', '991px': '90vw'}" [style]="{width: '500px'}" [showHeader]="false"
  [resizable]="false">
  <div class="onboard-user-form-wrapper">
    <form #frmEvent="ngForm">
      <!-- start new html-->
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content radius-24 p-0">
          <div class="modal-header d-flex justify-content-md-start justify-content-center h-auto radius-0">
            <h1 class="modal-title ps-modal-header-title fs-5" id="exampleModalLabel">
              Add to Collection
            </h1>
            <button type="button" class="collection-btn-close btn-close m-0 position-absolute end-0 me-4" 
              aria-label="Close" (click)="closeToCollectionModal()"></button>
          </div>

          <div class="modal-body">
            <div class="row pt-2" *ngIf="collectionList$ | async as collectionList">
              <div class="col-lg-12 pe-0 ps-3">
                <ul class="list-group list-group-flush border-bottom collections__lists cursorpointer">
                  <li
                    class="list-group-item font-medium collections__list-item d-flex justify-content-between align-items-center"
                    *ngFor="let item of collectionList;let ind=index;" (click)="saveToCollection(item)">
                    <p class="m-0 text-break">{{item.name}}</p>
                    <button class="btn me-3 collections__lists-btn">
                      <img src="./assets/images/icons/icon-add-to-collection-fill.svg" alt="add to collection icon" />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="no-collection row pt-2" *ngIf="(collectionList$ |async) && !(collectionList$ |async).length">
              <div class="placeholderimg custom-placeholder-image-collection">
                <img src="/assets/images/icons/no-assets-placeholder-img.svg" alt="no assets placeholder image">
                <br>
                <span>Get started with organizing your assets by creating</span>
                <span>your first collection! Click on "New Collection"</span>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-center align-items-start py-3">
            <button type="button" class="btn btn--1 btn-new-collection px-4 py-2 font-medium"
              (click)="openToCreateCollection()">
              New Collection
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</p-dialog>

<p-dialog *ngIf="isCreateModalShow" [modal]="true" [draggable]="false" [visible]="isCreateModalShow" class="onboarduser-form" [resizable]="false"
  [breakpoints]="{'1030px': '850px', '991px': '90vw'}" [style]="{width: '800px'}" [style]="{width: '600px'}"
  [showHeader]="false">
  <div class="onboard-user-form-wrapper">
    <form [formGroup]="createCollectionFrom">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content radius-24 p-0">
          <div class="modal-header d-flex justify-content-md-start justify-content-center h-auto radius-0">
            <h1 class="modal-title ps-modal-header-title fs-5" id="exampleModalLabel">
              New Collection
            </h1>
            <button type="button" class="collection-btn-close btn-close m-0 position-absolute end-0 me-4" 
              aria-label="Close" (click)="onCancel()"></button>
          </div>
          <div class="modal-body p-4">
            <div class="row align-items-start">
              <div class="col-sm">
                <div class="form-group mb-0">
                  <label for="name" class="form-label add-new-event-form-label">Collection
                    Name<span class="text-clr-important">*</span></label>
                  <input type="text" id="name" #name class="form-control" formControlName="name" name="name" required
                    placeholder="Enter Name" maxlength="{{totalText}}"
                    (keydown.enter)="submitFormData(createCollectionFrom)">
                  <!-- <textarea id="name" #name class="form-control" formControlName="name"
                     required  placeholder="Enter Name" (ngModelChange)="valueChange(name)"
                     maxlength="{{totalText}}"  rows="1"></textarea> -->
                  <div class="invalid-feedback d-block font-medium mb-2"
                    *ngIf="createCollectionFrom.controls['name'].hasError('required') && createCollectionFrom.controls['name'].dirty">
                    Name is required
                  </div>
                  <div class="invalid-feedback d-block font-medium mb-2"
                    *ngIf="createCollectionFrom.get('name').hasError('minlength')">
                    Name must be at least 2 characters long.
                  </div>
                  <div class="invalid-feedback d-block font-medium mb-2"
                    *ngIf="createCollectionFrom.get('name').hasError('maxlength')">Name
                    cannot
                    exceed 100
                    characters. </div>
                  <div class="invalid-feedback d-block font-medium mb-2"
                    *ngIf="createCollectionFrom.get('name').hasError('pattern')">
                    Only - and _ are allowed as special characters and do not enter leading,
                    trailing or
                    multiple spaces between
                    words
                  </div>
                  <div class="font-light text-end p-2 ">
                    <span>{{name && name.value && name.value.length || '0'}}</span>/{{totalText}}
                  </div>

                </div>
              </div>

            </div>
          </div>
          <div class="modal-footer justify-content-center align-items-start px-4 pb-4">
            <div class="row">
              <div class="col">
                <div class="d-grid d-flex gap-3 pt-3 flex-wrap justify-content-center">
                  <button type=" button " (click)="resetInput()"
                    class="btn btn-secondary btn-min-width justify-content-center save-cropped-img me-2"
                     [disabled]="!createCollectionFrom.dirty" style="color: #116ACC; border: 1px solid #116ACC;">Reset</button>
                  <button type="submit" class="btn border-0 d-btn-primary btn-primary btn-min-width"
                    (click)="submitFormData(createCollectionFrom)"
                    [disabled]="createCollectionFrom.invalid || (createCollectionFrom.controls['name'].length >=1 && createCollectionFrom.controls['name'].length <2 || createCollectionFrom.controls['name'].length > 100) && (createCollectionFrom.controls['name'].dirty || createCollectionFrom.controls['name'].touched || createCollectionFrom.submitted)">Create</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </form>
  </div>
</p-dialog>

<app-confirmationPopUp *ngIf="isDeleteCollectionModal" (onCancel)="closedeleteModal()"
  [show]="isDeleteCollectionModal" (onConfirm)="deleteCollectionFromAsset()"></app-confirmationPopUp>
