import { AppDataService } from './app.data.service';
import { AppService } from './app.service';
import { AuthService } from './auth.service';
import { EventEmitterService } from './event-emitter.service';
import { HeaderService } from './header.service';
import { LoaderService } from './loader.service';
import { LocalStorageService } from './local-storage.service';
import { LoggerService } from './logger.service';
import { PermissionsEngine } from './permission.service';
import { RestService } from './rest.service';
import { S3UploadServices } from './s3-upload-services.service';
import { UserDataService } from './user-data.service';

export * from './app.service';
export * from './auth.service';
export * from './permission.service';
export * from './loader.service';
export * from './logger.service';
export * from './event-emitter.service';
export * from './header.service';
export * from './s3-upload-services.service';
export * from './app.data.service';
export * from './user-data.service';
export * from './local-storage.service';
export * from './rest.service';

export const services = [
  AppService,
  AuthService,
  PermissionsEngine,
  LoaderService,
  LoggerService,
  EventEmitterService,
  HeaderService,
  S3UploadServices,
  AppDataService,
  UserDataService,
  LocalStorageService,
  RestService,
];
