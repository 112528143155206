<p-dialog id="createEventContainer" [modal]="true" [visible]="true" [breakpoints]="{'1030px': '850px', '991px': '90vw'}"
  [style]="{width: '800px'}" [showHeader]="false" class="onboarduser-form" [resizable]="false" [draggable]="false">
  <div class="onboard-user-form-wrapper">
    <form #frmEvent="ngForm">
      <!-- start new html-->
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content radius-24 p-0">
          <div class="modal-header border-0 pb-0">
            <h3 >Add Event</h3>
            <button type="button" (click)="closeEventModal(frmEvent)" class="btn-close" aria-label="Close"></button>
          </div>
          <div class="modal-body p-4 pt-3">
            <div class="row pt-2">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="mb-4">
                      <label for="eventName" class="form-label add-new-event-form-label">Event Name&nbsp;</label>
                      <span class="invalid-feedback font-medium d-inline-block w-auto">*</span>
                      <input type="text" class="form-control" [(ngModel)]="event.eventName" name="eventName"
                        #eventName="ngModel" id="firstName" required placeholder="Enter Event Name"
                        pattern="^[a-zA-Z0-9@.\-_ ]+$">
                      <div class="invalid-feedback d-block font-medium mb-2"
                        *ngIf="(event.eventName?.length >=1 && event.eventName?.length <2 || event.eventName?.length > 40) && (eventName.dirty || eventName.touched || frmEvent.submitted)">
                        Event Name should range from 2 to 40 characters</div>
                      <div *ngIf="eventName.errors && (eventName.dirty || eventName.touched || frmEvent.submitted)"
                        class="invalid-feedback d-block font-medium mb-2">
                        <div *ngIf="eventName.errors?.['required']">Event Name is required</div>
                        <div *ngIf="eventName.errors?.['pattern']">Only [&#64;, ., -, _] are allowed as special characters
                        </div>
                      </div>
                    </div>
                    <div class="mb-4">
                      <label for="eventTime" class="form-label add-new-event-form-label">Event Date&nbsp;</label>
                      <span class="invalid-feedback font-medium d-inline-block w-auto">*</span>
                      <input type="date" onkeydown="return false"
                        onfocus="max=new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split('T')[0]"
                        class="form-control" [(ngModel)]="event.eventTime" name="eventTime" #eventTime="ngModel"
                        id="eventTime" min="1900-01-01" max="9999-12-31" required>
                      <div *ngIf="eventTime.errors && (eventTime.dirty || eventTime.touched || frmEvent.submitted)"
                        class="invalid-feedback d-block font-medium mb-2">
                        <div *ngIf="eventTime.errors?.['required']">Event Date is required</div>
                      </div>
                    </div>
                    <div class="mb-4">
                      <label for="agency" class="form-label add-new-event-form-label">Event Location&nbsp;</label>
                      <span class="invalid-feedback font-medium d-inline-block w-auto">*</span>
                      <input type="text" class="form-control" [(ngModel)]="event.eventLocation" name="eventLocation"
                        #eventLocation="ngModel" id="firstName" required placeholder="Event Location"
                        pattern="^[a-zA-Z0-9@.\-_ ]+$">

                      <div class="invalid-feedback d-block font-medium mb-2"
                        *ngIf="(event.eventLocation?.length >=1 && event.eventLocation?.length < 2 || event.eventLocation?.length > 20) && (eventLocation.dirty || eventLocation.touched || frmEvent.submitted)">
                        Event Location should range from 2 to 20 characters</div>
                      <div
                        *ngIf="eventLocation.errors && (eventLocation.dirty || eventLocation.touched || frmEvent.submitted)"
                        class="invalid-feedback d-block font-medium mb-2">
                        <div *ngIf="eventLocation.errors?.['required']">Event Location is required</div>
                        <div *ngIf="eventLocation.errors?.['pattern']">Only [&#64;, ., -, _] are allowed as special
                          characters </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="position-relative textarea-h-100">
                      <label for="description" class="form-label add-new-event-form-label">Description&nbsp;</label>
                      <span class="invalid-feedback font-medium d-inline-block w-auto">*</span>
                      <textarea class="form-control" [(ngModel)]="event.description" id="description" rows="3"
                        name="description" #description="ngModel" placeholder="Add Description" required
                        maxlength="{{totalText}}" required></textarea>
                      <div
                        *ngIf="description.errors && (description.dirty || description.touched || frmEvent.submitted)"
                        class="invalid-feedback d-block font-medium mb-2 textarea-error-msg">
                        <div *ngIf="description.errors?.['required']">Event Description is required</div>
                      </div>
                      <span class="remaning">{{description && description.value && description.value.length || '0'}}
                        / {{totalText}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-start align-items-start px-4 pt-2 pb-4">
            <div class="d-grid d-flex gap-3 flex-wrap">
              <button type="button" class="btn btn-secondary btn-min-width mx-2" data-bs-dismiss="modal"
                (click)="resetFormData(frmEvent)">Reset</button>
              <button type="submit" class="btn btn-primary d-btn-primary btn-min-width"
                [disabled]="frmEvent.invalid || ((event.eventName?.length >=1 && event.eventName?.length <2 || event.eventName?.length > 40) && (eventName.dirty || eventName.touched || frmEvent.submitted)) || (event.eventLocation?.length >=1 && event.eventLocation?.length < 2 || event.eventLocation?.length > 20) && (eventLocation.dirty || eventLocation.touched || frmEvent.submitted)"
                (click)="submitFormData(frmEvent)">Save</button>
            </div>
          </div>
        </div>
      </div>
      <!-- end new html-->
    </form>
  </div>
</p-dialog>
