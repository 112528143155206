<div class="client-main">
  <header class="header">
    <nav>
      <div class="align-items-center d-flex heading">
        <div class="logo_img">
          <img src="assets/images/pages/header/jio-logo.svg" alt="jio-logo">
          <div class="logo_text">DAM</div>
        </div>
      </div>
    </nav>
  </header>
  <div class="outlet">
    <router-outlet></router-outlet>
  </div>
</div>
