<ul id="myUL" class="cursor-p-removed folder-tree pb-0" *ngIf="items.children" [ngClass]="{'active': items.children,  'tooltipPosition': items.children?.length-1 }">
  <li class="tree-li position-static p-0" *ngFor="let item of items.children; let ind = index">
    <div *ngIf="checkFolderAccessForModal(item)"
      class="cursor-p-removed caret align-items-start pe-1 position-relative custom-tooltip-hover"
      [ngClass]="{'caret-down': item.isCollapse}" oncontextmenu="return false;">
      <div class="d-flex cursor-p-apply align-items-center" id="item-{{item.folderId}}" (contextmenu)="handleFolderClick($event,item,ind)"
        (click)="[handleFolderClick($event,item,ind),disableContextMenu() ]">
        <i [ngClass]="item.folderCount>=1?'arrow-icon':''"></i>
        <i class="item-icon"></i>
        <span class="item-folder-name">{{item.folderName}}</span>
      </div>
      <span class="hover-text-details text-center" *ngIf="assetCountDisableFlag">{{item.assetConts}}</span>
    </div>

    <div class="ps-3"
      *ngIf="item.children && item.children.length>0 && item.isCollapse ">
      <app-dynamic-nested-list [items]="item" [folderContextMenuList]="folderContextMenuList" [parentId]="parentId"
        (refresh)="refreshed($event)" (refreshCopyFolder)="openCopyfolder($event)"
        (folderRefreshMethod)="openFolderRefreshMethod($event)" [assetCountDisableFlag]="assetCountDisableFlag">
      </app-dynamic-nested-list>
    </div>
  </li>
</ul>


<!-- contextmenu Modal -->

<nav *ngIf="currentItem?.isFolderRightClick" id="currentItemUnique-{{currentItem.folderId}}"  #contextMenuRightClick [ngClass]="currentItem?.isFolderRightClick ? 'opened' : 'closed' "
  class="contextmenu position-fixed context-menu d-block checkRightClickClass" [ngStyle]="{'left.px': contextmenuX, 'top.px': contextmenuY}">
  <app-context-menu #contextMenuBodyClass  [menuitems]="folderContextMenuList"
    (clickedOutside)="clickedOutsideMenu()" (menuItemSelected)="handleMenuSelection($event)"></app-context-menu>
</nav>

<!--rename Modal-->

<p-dialog [modal]="true" [visible]="renameModal" [resizable]="false"
  [breakpoints]="{'1030px': '850px', '991px': '90vw'}" [style]="{width: '600px'}" [showHeader]="false"
  class="onboarduser-form">
  <div class="d-flex justify-content-between flex-row p-3 cfbg">
    <h3 class="font-bold">Update Folder Name</h3>
    <button type="button" (click)="cancel()" class="btn-close" aria-label="Close"></button>
  </div>
  <form [formGroup]="renameForm" class="p-3">
    <div class="form-group pt-2 pb-3">
      <input type="text" class="form-control mt-1 mb-2" aria-describedby="emailHelp" placeholder="Enter New Name"
        required formControlName="updatedName" autofocus>
      <div class="invalid-feedback d-block font-medium mb-2"
        *ngIf="renameForm.controls['updatedName'].hasError('required') && renameForm.controls['updatedName'].dirty">
        Folder Name is required
      </div>
      <div class="invalid-feedback d-block font-medium mb-2"
        *ngIf="renameForm.get('updatedName').hasError('minlength')">
        Folder Name should range from 2 to 100 characters
      </div>
      <div class="invalid-feedback d-block font-medium mb-2"
        *ngIf="renameForm.get('updatedName').hasError('maxlength')">
        Folder Name must range between 2 to 100 characters.
      </div>
      <div class="invalid-feedback d-block font-medium mb-2" *ngIf="renameForm.get('updatedName').hasError('pattern')">
        Only - and _ are allowed as special characters.
      </div>
    </div>
    <button type="submit"
      [disabled]="renameForm.invalid || (renameForm.controls['updatedName'].length >=1 && renameForm.controls['updatedName'].length <2) && (renameForm.controls['updatedName'].dirty || renameForm.controls['updatedName'].touched || renameForm.submitted) || (renameForm.controls['updatedName'].value === this.folderName)"
      (click)="updateName()" class="btn btn-primary d-btn-primary border-0 btn-min-width me-2">Submit</button>
    <button type="submit" (click)="cancel()" class="btn btn-secondary btn-min-width">Cancel</button>
  </form>
</p-dialog>

<!-- Delete Modal -->
<app-confirmationPopUp *ngIf="isDeleteModalShow" (onCancel)="closedeleteModal()"
  [show]="isDeleteModalShow" (onConfirm)="onDeleteFolder()"></app-confirmationPopUp>

<!--This Modal is used to display folder tagging page when user tries to tag complete folder-->
<p-dialog [modal]="true" [visible]="isFolderTag && totalUntagRecords>0" [resizable]="false" [draggable]="false"
  [breakpoints]="{'1030px': '850px', '991px': '90vw'}" [style]="{width: '800px'}" class="onboarduser-form"
  [showHeader]="false">
  <div class="onboard-user-form-wrapper">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
      <div class="modal-content radius-24 p-0">
        <div class="modal-header border-0 pb-0" style="height: 60px;">
          <h3 style="font-size: 22px;">Tag Folder</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="goBack()"
            aria-label="Close"></button>
        </div>
        <form [formGroup]="uploadTagsForm">
          <div class="modal-body p-4 pt-3">
            <div class="row pt-2 pb-5">
              <div class="col-lg-12">
                <div class="my-3 tagfoldersec">
                  <h3 class="tagfolderheading"> {{folderName || ''}}</h3>
                  <p class="tagfoldersubtxt"
                    *ngIf="(assetPendingCount>0 || assetCompleteCount>0) && assetCompleteCount!=totalUntagRecords">
                    Currently
                    <span class="tagfoldersubtxt" *ngIf="assetPendingCount>0 ">{{assetPendingCount }} Pending<span
                        *ngIf="assetPendingCount>0 && assetCompleteCount>0">,</span></span>
                    <span class="tagfoldersubtxt" *ngIf="assetCompleteCount>0 ">{{assetCompleteCount }} completed</span> tag assets in this
                    folder
                  </p>
                  <p class="tagfoldersubtxt" *ngIf="assetCompleteCount==totalUntagRecords">Currently No Pending tag
                    Assets in this folder</p>
                </div>
              </div>

              <div class="col-12 col-lg-6">
                <div class="mb-4">
                  <img src="/assets/images/pages/context-menu/Folder.svg" alt="tagging status">
                </div>
                <div>
                  <h5>Tagging Status</h5>
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="completed_text mb-2 radio">
                      <input type="radio" id="tag-completed" class="me-1" name="assetTag" [checked]="assetTag==1"
                        [value]="1" formControlName="assetTag">
                      <label for="tag-completed" class="radio-label d-flex">
                        Completed
                      </label>
                    </div>
                    <div class="pending_text mb-2 radio">
                      <input type="radio" id="tag-pending" class="me-1" name="assetTag" [checked]="assetTag==0"
                        [value]="0" formControlName="assetTag">
                      <label for="tag-pending" class="radio-label d-flex">
                        Pending
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 p-0 m-0 flex-column">


                <div class="row">
                  <div class="col-12 d-flex flex-column">
                    <div class="form-outline">
                      <label class="form-label">Manual Tags
                      </label>
                      <div class="card border-0 radius-8 h-100 overflow-visible">
                        <div class="p-0 dam-neutral-80 ">
                          <div>
                            <div class="d-grid d-flex flex-wrap gap-2 py-2">
                              <p-chips #chips class="full-width-element w-100" formControlName="tagArray" type="reset" [addOnTab]="true">
                              </p-chips>
                              <span class="fs-12 font-medium text-dark mt-1">
                                Note: Press Enter or Tab key after you added
                                the tag name
                              </span>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div>
                      <div class="row">
                        <div class="col-12">
                          <div class="mb-4">
                            <div class="row">
                              <div class="col">
                                <label for="exampleFormControlInput1" class="form-label">Event
                                  Name
                                </label>
                              </div>
                            </div>
                            <div class="row">

                              <div>
                                <div class="col">
                                  <label for="exampleFormControlInput1" class="form-label">Event
                                    Name
                                  </label>
                                </div>
                                <p-dropdown [options]="eventsList" placeholder="--Select Event--"
                                  optionLabel="eventName" optionValue="id" formControlName="eventId" [showClear]="true"
                                  [style]="{'width':'100%'}" scrollHeight="80px" [filter]="true"
                                  [resetFilterOnHide]="true">

                                </p-dropdown>

                              </div>



                              <div class="ps-2 d-flex justify-content-end mt-4">
                                <button class="btn btn-primary d-btn-primary border-0" (click)="openEventModal()">
                                  <i class="nm_icon me-2 fs-24">
                                    <img src="./assets/images/icons/icon-plus.svg" class="p-1" height="20px"
                                      width="20px" alt="plus_icon">
                                  </i>
                                  Create Event
                                </button>
                              </div>
                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>



              </div>

            </div>
          </div>
          <div class="modal-footer justify-content-start align-items-start pt-2 px-3 pb-4 gap-2">
            <button type="button" class="btn btn-secondary btn-min-width btn-lg"
              (click)="goBack()">Cancel</button>
            <button type="button" class="btn btn-primary new-btn btn-min-width btn-lg" (click)="uploadTags()"
              [disabled]="!uploadTagsForm.dirty">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>

</p-dialog>

<!--Create event modal-->

<app-add-event-modal *ngIf="IsmodelEventShow" (closeEvent)="closeEventModal($event)"></app-add-event-modal>

<!-- dialog box for creating folder -->

<app-create-folder *ngIf="isCreateModalShow" (triggerMethod)="closeCreateModal($event)"
  [folderDetails]="createfolderDetails" [objectForSourceTrack]="objectForSourceTrack" [folderType]="folderType">
</app-create-folder>

<!-- import Folder By S3 Path Modal start -->
<p-dialog [modal]="true" [draggable]="false" [visible]="isImportFolderByS3PathShow" class="onboarduser-form"
  [resizable]="false" [breakpoints]="{'1030px': '850px', '991px': '90vw'}" [style]="{width: '600px'}"
  [showHeader]="false">
  <div class="onboard-user-form-wrapper">
    <form>
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content radius-24 p-0">
          <div class="modal-header border-0 pb-0">
            <h3>{{importConfig.importFromLabel}}</h3>
            <button type="button" (click)="closeimportFolderByS3PathModal()" class="btn-close"
              aria-label="Close"></button>
          </div>
          <div class="modal-body p-4 pt-3">
            <div class="row pt-2">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-9">
                    <div class="mb-4">
                      <div class="mb-4">
                        <div class="mb-2 font-bold">
                          {{importConfig.importFrom}} Path
                          <span style="color: #ff0000;">*</span>
                        </div>
                        <input type="text" class="form-control" name="s3Path" required
                          placeholder="Enter {{importConfig.importFrom}} Path..." [(ngModel)]="s3PathToImport">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-start align-items-start px-4 pt-2 pb-4">
            <div class="d-grid d-flex gap-3 flex-wrap">
              <button type="submit" class="btn btn-primary btn-min-width" [disabled]="s3PathToImport==''"
                (click)="importFolderByS3Path()">Import</button>
              <button type="button" (click)="closeimportFolderByS3PathModal()"
                class="btn btn-secondary btn-min-width">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</p-dialog>
<!-- import Folder By S3 Path Modal end -->

<!-- dialog box for upload assets -->
<app-upload *ngIf="isUploadModalClose" (closeModalEvent)="closeModalEvent($event)" [childFolderId]="childFolderId"
  [assetPathofFolder]="assetUploadPath" [modalType]="modalType"></app-upload>